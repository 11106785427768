import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { API_URL } from '../config';
import { Spacing, Button, TextField, LoadingElm } from '../components';
import ReactLoading from "react-loading";
import Logo from '../assets/img/logo-dark.png';
import SearchIcon from '../assets/svg/search.svg';
import ArrowRightIcon from '../assets/svg/arrowRight.svg';
import ArrowRightCircleIcon from '../assets/svg/arrowRightCircle.svg';
import ProfileIcon from '../assets/svg/profile.svg';
import ProblemIcon from '../assets/svg/problem.svg';
import SearchEnterIcon from '../assets/svg/searchEnter.svg';
import SearchCloseIcon from '../assets/svg/searchClose.svg';
import PREVIEW_IMG from '../assets/img/preview.jpg';

//Theme object
const theme = {
	colors: {
		background: '#fafafa',
		primary: '#df2e2e',
		textPrimary: '#000000',
		textSecondary: '#7c7c7c',
		textOnPrimary: '#ffffff',
		card: '#ffffff'
	},
	borderRadius: {
		card: 15
	},
	fonts: {
		regular: 'Proxima Nova Regular, sans-serif',
		bold: 'Proxima Nova Bold, sans-serif'
	},
	shadow: '0 0 8px #00000019'
}

export default function Home ({ notFound }) {

	//Used hooks
	const { t } = useTranslation();
	const location = useLocation();
	const isXS = useMediaQuery({ query: '(max-width: 500px)' });
	const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
	const isLG = useMediaQuery({ query: '(max-width: 1100px)' });

	//Used parameters
	const urlParams = new URLSearchParams(location.search);
	const pageParam = urlParams.get('page') ? parseInt(urlParams.get('page')) : 1;
	const searchParam = urlParams.get('search') ? urlParams.get('search') : '';
	
	//Set used state
    const [ loading, setLoading ] = useState(true);
    const [ imgLoading, setImgLoading ] = useState([]);
    const [ error, setError ] = useState(null);
	const [ items, setItems ] = useState([]);
	const [ count, setCount ] = useState(0);
	const [ page, setPage ] = useState(pageParam > 0 ? pageParam : 1);
	const [ search, setSearch ] = useState(searchParam);

	//Used variables
	const imgLoadingRef = useRef(imgLoading);
	const gridSize = isXS ? '1' : isMobile ? '2' : isLG ? '3' : '4';
	const limit = 12;
	const pages = new Array(Math.ceil(count / limit)).fill(undefined).map((_, i) => i + 1);
	const empty = items.length === 0;

	//Used methods
	const pageSelect = (value) => {
		setPage(value);
		fetchData(value);
	}

	const fetchData = (selectedPage=null, typedSearch=null) => {
		const usedPage = selectedPage !== null ? selectedPage : page;
		const usedSearch = typedSearch !== null ? typedSearch : search;
		setLoading(true);
		setError(null);
		setItems([]);
		setCount(0);
		const searchParam = usedSearch ? '&text=' + usedSearch : '';
		fetch(API_URL + `/search?page=${usedPage}&limit=${limit}${searchParam}`)
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.count) setCount(res.count);
			if (res.items) {
				setItems(res.items);
				imgLoadingRef.current = res.items.map((item) => item._id);
				setImgLoading(imgLoadingRef.current);
			}
		})
		.catch((err) => setError(err.message))
		.finally(() => setLoading(false));
	}

	//Used effect
	useEffect(() => fetchData(), []);

	return (
		<div style={{ width: '100vw', height: '100vh', overflow: 'auto', backgroundColor: theme.colors.background,
			color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
			<div style={{ width: !isMobile ? '80%' : '90%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Spacing mt={40}/>
				<div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{!isMobile && <div style={{ flex: 1 }}></div>}
					<Link to='/'><img src={Logo} height={40}/></Link>
					<div style={{ flex: 1 }}></div>
					<Link to='/account' style={!isMobile ? { position: 'absolute' , right: 0, textDecoration: 'none' } : {}}>
						{!isMobile ? <Button outline title={t('home.login')}/> : <img src={ProfileIcon} height={40}/>}
					</Link>
				</div>
				<Spacing mt={40}/>
				<TextField placeholder={t('home.searchMsg')} value={search} onChange={setSearch} icon={SearchIcon}
					enterIcon={SearchEnterIcon} closeIcon={search ? SearchCloseIcon : undefined}
					onEnter={() => fetchData(1)} onClose={() => { setSearch(''); fetchData(1, ''); }}
					inputStyle={{ width: !isMobile ? 400 : 'calc(90vw - 20px)' }} containerStyle={{ maxWidth: 'calc(90vw - 20px)' }}/>
				<Spacing mt={30}/>
				{notFound && <>
					<h3 style={{ margin: 0, color: theme.colors.primary }}>{t('home.notFoundMsg')}</h3>
					<Spacing mt={30}/>
				</>}
				<div style={{ backgroundColor: theme.colors.primary, borderRadius: theme.borderRadius.card, width: '100%',
					padding: '20px 30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxSizing: 'border-box' }}>
					<h2 style={{ margin: 0, color: theme.colors.textOnPrimary, fontWeight: 'normal' }}>{t('home.callMsg')}</h2>
					<Spacing mr={20}/>
					<Link to='/account/signup' style={{ textDecoration: 'none' }}>
						{!isMobile ? (
							<Button outline title={t('home.signup')} icon={ArrowRightIcon} loadingColor={theme.colors.textOnPrimary}
								inputStyle={{ borderColor: theme.colors.textOnPrimary, color: theme.colors.textOnPrimary }}/>
						) : (
							<img src={ArrowRightCircleIcon} height={40}/>
						)}
					</Link>
				</div>
				<Spacing mt={30}/>
				{loading ? <>
					<LoadingElm/>
				</> : error ? <>
					<img src={ProblemIcon} width={100}/>
					<Spacing mt={20}/>
					<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
					<Spacing mb={5}/>
					<span style={{ color: theme.colors.textSecondary }}>{error}</span>
					<Spacing mb={20}/>
					<Button title={t('common.tryAgain')} onClick={() => fetchData()}/>
				</> : empty ? <>
					<span style={{ color: theme.colors.textSecondary }}>{t('home.emptyMsg')}</span>
				</> : <>
					{<div style={{ width: '100%', display: 'grid', gridGap: '50px', gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
						{items.map((item, i) => <Link key={item._id} to={'/' + item.url} style={{ textDecoration: 'none' }}>
							<div style={{ position: 'relative', padding: '15px', backgroundColor: theme.colors.card,
								boxShadow: theme.shadow, borderRadius: theme.borderRadius.card, color: theme.colors.textPrimary }}>
								<img src={PREVIEW_IMG} width="100%" style={{ aspectRatio: "1" }}/>
								<img src={API_URL + `/campaign?pic=${item.url}-0-${item.languages[0].frameIndexes[0]}`}
									style={{ position: 'absolute', top: 15, left: 15, width: 'calc(100% - 30px)' }}
									onLoad={() => {
										imgLoadingRef.current = imgLoadingRef.current.filter((id) => id !== item._id);
										setImgLoading(imgLoadingRef.current)
									}}/>
								{imgLoading.includes(item._id) && <div style={{ position: 'absolute', width: '100%', height: '100%',
									top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<ReactLoading type="spinningBubbles" width={50} height={50} color={theme.colors.primary}/>
								</div>}
								<Spacing mt={10}/>
								<span>{item.languages[0].campaignTitle}</span>
							</div>
						</Link>)}
					</div>}
					<Spacing mt={30}/>
					<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
						{pages.length > 1 && pages.map((item, i) => <div key={i} onClick={() => pageSelect(item)} style={{
							borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
							width: 50, height: 50, marginBottom: 10, marginRight: i < pages.length - 1 ? 10 : 0,
							...(item === page ? {
								backgroundColor: theme.colors.primary, color: theme.colors.textOnPrimary
							} : {
								borderStyle: 'solid', borderWidth: 2, boxSizing: 'border-box', borderColor: theme.colors.primary
							}) }}>
							{item.toString()}
						</div>)}
					</div>
					<Spacing mt={10}/>
					<span>{t('home.contactMsg')}</span>
				</>}
				<Spacing m={10}/>
			</div>
        </div>
	);

}