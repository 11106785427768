import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useMediaQuery } from 'react-responsive';
import { API_URL } from '../config';
import { ImageUpload } from '../utils';
import { Spacing, Button, Select, LoadingElm } from '../components';
import ReactLoading from "react-loading";
import Cropper from "react-cropper";
import ProblemIcon from '../assets/svg/problem.svg';
import ResetIcon from '../assets/svg/reset.svg';
import FacebookIcon from '../assets/svg/facebook.svg';
import PREVIEW_IMG from '../assets/img/preview.jpg';

//View size
const VIEW_SIZE = 250;
//Theme object
const theme = {
	colors: {
		background: '#fafafa',
		primary: '#df2e2e',
		secondary: '#eaeaea',
		textPrimary: '#000000',
		textSecondary: '#7c7c7c',
		border: '#bababa',
		card: '#ffffff'
	},
	fonts: {
		regular: 'Proxima Nova Regular, sans-serif',
		bold: 'Proxima Nova Bold, sans-serif'
	},
	borderRadius: {
		input: 35,
		card: 15
	},
	shadow: '0 0 8px #00000019'
}

export default function UserApp () {

	//Used hooks
	const { t } = useTranslation();
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const params = useParams();
	const isMobile = useMediaQuery({ query: '(max-width: 660px)' });
	const isMobileHeight = useMediaQuery({ query: '(max-height: 320px)' });
	
	//Set used state
    const [ loading, setLoading ] = useState(true);
    const [ facebookLoading, setFacebookLoading ] = useState(false);
    const [ imgLoading, setImgLoading ] = useState(true);
    const [ error, setError ] = useState(null);
	const [ campaign, setCampaign ] = useState(null);
	const [ langIndex, setLangIndex ] = useState(0);
	const [ frameIndex, setFrameIndex ] = useState(0);
	const [ imageData, setImageData ] = useState(null);
	const [ downloadData, setDownloadData ] = useState(null);

	//Used variable
	const langIndexRef = useRef(langIndex);
	const frameIndexRef = useRef(frameIndex);
	const canvas = useRef(null);
	const previewImage = useRef(null);
	const frameImage = useRef(null);
	const cropContainer = useRef(null);
	const croppedImage = useRef(null);
	const languages = campaign && campaign.languages.map((item, i) => { return { value: i, label: item.languageTitle } });
	const currentLanguage = campaign && campaign.languages[langIndex];
	const isMultiFrame = campaign && currentLanguage.frameIndexes.length > 1;

	//Used methods
	const browseImage = () => {
		ImageUpload(null, null, null, true)
		.then((res) => setImageData(res))
		.catch(() => addToast(t('userapp.fileTypeErr'), { appearance: 'error', fontFamily: theme.fonts.regular }));
	}

	const Promisfy = (func, param=null, postParam=null) => {
		return new Promise((resolve) => param ? func(param, resolve) : postParam ? func(resolve, postParam) : func(resolve));
	}

	const fbUpload = async () => {
		setFacebookLoading(true);
		const statusResponse = await Promisfy(FB.getLoginStatus);
		if (statusResponse.status === 'connected') await Promisfy(FB.logout);
		const loginResponse = await Promisfy(FB.login, null, { scope: 'public_profile', auth_type: 'rerequest' });
		if (loginResponse.status === 'connected') {
			setImageData("https://graph.facebook.com/" + loginResponse.authResponse.userID + "/picture?type=large&height=512&width=512");
		} else {
			addToast(t('userapp.fbLoginErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
		}
		setFacebookLoading(false);
	}

	const downloadImage = () => {
		const base64 = canvas.current.toDataURL("image/png");
		const link = document.createElement("a");
		link.setAttribute("href", base64);
		link.setAttribute("download", "image.png");
		document.body.appendChild(link);
		link.click();
		link.remove();
		setDownloadData(base64);
		//Send download event
		fetch(API_URL + '/stats', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ url: campaign.url, langIndex: langIndexRef.current, frameIndex: frameIndexRef.current })
		})
		.catch(() => {});
	}

	const reset = () => {
		setImageData(null);
		setDownloadData(null);
		croppedImage.current = null;
	}

	const drawCanvas = (image=null) => {
		const usedImage = image || croppedImage.current;
		if (image) croppedImage.current = image;
		const canvasCtx = canvas.current.getContext("2d");
		frameImage.current.src = API_URL + `/campaign?pic=${campaign.url}-${langIndexRef.current}-${frameIndexRef.current}`;
		frameImage.current.onload = () => {
			setImgLoading(false);
			const drawCall = (img=null) => {
				if (!img) canvasCtx.drawImage(previewImage.current, 0, 0, canvas.current.width, canvas.current.height);
				else {
					canvasCtx.drawImage(img, 0, 0, canvas.current.width, canvas.current.height);
					img.remove();
				}
				canvasCtx.drawImage(frameImage.current, 0, 0, canvas.current.width, canvas.current.height);
				if (!usedImage) {
					const fontSize = parseInt(canvas.current.width / 12);
					canvasCtx.font = `bold ${fontSize.toString()}px sans-serif`;
					canvasCtx.fillStyle = '#ffffff';
					canvasCtx.textAlign = "center";
					canvasCtx.shadowColor = "#000000";
					canvasCtx.shadowBlur = 10;
					canvasCtx.fillText(campaign.multiLanguage && currentLanguage.previewMsg ? currentLanguage.previewMsg : t('userapp.previewMsg'),
						canvas.current.width / 2, canvas.current.height / 2
					);
				}
				if (campaign.watermark) {
					const fontSize = parseInt(canvas.current.width / 32);
					canvasCtx.font = `bold ${fontSize.toString()}px sans-serif`;
					canvasCtx.fillStyle = 'red';
					canvasCtx.textAlign = "left";
					canvasCtx.shadowBlur = 0;
					canvasCtx.fillText(window.location.host + '/' + campaign.url, fontSize, canvas.current.height - fontSize);
				}
			};
			canvasCtx.clearRect(0, 0, canvas.current.width, canvas.current.height);
			if (!usedImage) drawCall();
			else {
				const img = new Image();
				img.src = usedImage;
				img.onload = () => drawCall(img);
			}
		};
	}

	const frameSelect = (index) => {
		if (frameIndexRef.current === index) return;
		frameIndexRef.current = index;
		setFrameIndex(index);
	}

	const changeLanguage = (index) => {
		langIndexRef.current = index;
		frameIndexRef.current = 0;
		setImgLoading(true);
		setLangIndex(index);
		setFrameIndex(0);
		//Send view event
		fetch(API_URL + '/stats', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ url: campaign.url, langIndex: index })
		})
		.catch(() => {});
	}

	const fetchCampaign = () => {
		setLoading(true);
		setError(null);
		fetch(API_URL + '/campaign?url=' + params.url)
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (!res.campaign) navigate('/404');
			else {
				setCampaign(res.campaign);
				//Send view event
				fetch(API_URL + '/stats', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ url: res.campaign.url, langIndex: 0 })
				})
				.catch(() => {});
			}
		})
		.catch((err) => setError(err.message))
		.finally(() => setLoading(false));
	}

	//Used effect
	useEffect(fetchCampaign, []);

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular,
			boxSizing: 'border-box', padding: '0px 15px', justifyContent: !isMobileHeight && (loading || error) ? 'center' : undefined }}>
			<img ref={previewImage} src={PREVIEW_IMG} style={{ display: 'none' }} width={VIEW_SIZE} height={VIEW_SIZE}/>
			<img ref={frameImage} crossOrigin="anonymous" style={{ display: 'none' }} width={VIEW_SIZE} height={VIEW_SIZE}/>
			{isMobileHeight && (loading || error) && <Spacing mt={40}/>}
			{loading ? <>
				<div><LoadingElm/></div>
				{isMobileHeight && <Spacing m={20}/>}
			</> : error ? <>
				<img src={ProblemIcon} width={100}/>
				<Spacing mt={20}/>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
				<Spacing mb={5}/>
				<span style={{ color: theme.colors.textSecondary }}>{error}</span>
				<Spacing mb={20}/>
				<Button title={t('common.tryAgain')} onClick={fetchCampaign}/>
				{isMobileHeight && <Spacing m={20}/>}
			</> : campaign ? <>
				{campaign.multiLanguage && <>
					<Select withLabel onChange={changeLanguage} items={languages} inputStyle={{ paddingLeft: 40 }}
						containerStyle={{ position: 'absolute', left: -25, top: 25 }}/>
				</>}
				<Spacing mt={50}/>
				<h1 style={{ margin: 0, fontFamily: theme.fonts.bold, textAlign: 'center', maxWidth: !isMobile ? '80%' : '90%' }}>
					{currentLanguage.campaignTitle}
				</h1>
				{currentLanguage.campaignSubtitle ? <>
					<Spacing mt={10}/>
					<span style={{ color: theme.colors.textSecondary, fontSize: '1.1em', textAlign: 'center',
						maxWidth: !isMobile ? '80%' : '90%' }}>
						{currentLanguage.campaignSubtitle}
					</span>
				</> : null}
				{isMultiFrame && <Spacing mt={40}/>}
				<div style={{ display: 'flex' }}>
					{isMultiFrame && currentLanguage.frameIndexes.map((item) => <div key={item}
						onClick={() => frameSelect(item)} style={{ cursor: 'pointer', borderStyle: 'solid', borderWidth: 2, padding: 3,
						width: 60, height: 60, borderColor: theme.colors.border,
						backgroundColor: item === frameIndex ? theme.colors.secondary : theme.colors.card,
						marginRight: item < currentLanguage.frameIndexes.length - 1 ? 20 : 0 }}>
						<img src={API_URL + `/campaign?pic=${campaign.url}-${langIndex}-${item}`}
							width='100%' height='100%'/>
					</div>)}
				</div>
				<Spacing mt={isMultiFrame ? 40 : 50}/>
				<div style={{ display: 'flex', flexDirection: !isMobile ? 'row': 'column-reverse', width: !isMobile ? 620 : undefined, flexWrap: 'wrap' }}>
					<div style={{ backgroundColor: theme.colors.card, boxShadow: theme.shadow, borderRadius: theme.borderRadius.card,
						padding: 15, position: 'relative', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{imgLoading && <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex',
							alignItems: 'center', justifyContent: 'center' }}>
							<ReactLoading type="spinningBubbles" width={50} height={50} color={theme.colors.primary}/>
						</div>}
						<span>{campaign.multiLanguage && currentLanguage.previewTitle ? currentLanguage.previewTitle : t('userapp.preview')}</span>
						<Spacing mt={15}/>
						<canvas ref={(ref) => { if (!ref) return; canvas.current = ref; drawCanvas(); }}
							width={campaign.size} height={campaign.size} style={{ width: VIEW_SIZE, height: VIEW_SIZE }}/>
					</div>
					{!isMobile ? <Spacing mr={60}/> : <Spacing mt={30}/>}
					<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
						<div onClick={!imageData ? browseImage : undefined} style={{ backgroundColor: theme.colors.card,
							boxShadow: theme.shadow, cursor: !imageData ? 'pointer' : 'default',
							borderRadius: theme.borderRadius.card, minHeight: 70, padding: 15, flex: 1, display: 'flex',
							flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
							<span>{!imageData ? (
								campaign.multiLanguage && currentLanguage.browseTitle ? currentLanguage.browseTitle : t('userapp.browseImage')
							) : (
								campaign.multiLanguage && currentLanguage.cropTitle ? currentLanguage.cropTitle : t('userapp.crop')
							)}</span>
							{!imageData ? null : <Spacing mt={15}/>}
							<Cropper style={{ width: VIEW_SIZE, height: VIEW_SIZE, display: !imageData ? 'none' : 'initial' }}
								ref={cropContainer} src={imageData} aspectRatio={1} autoCropArea={1} viewMode={1} guides={false}
								cropend={() => drawCanvas(cropContainer.current.cropper.getCroppedCanvas().toDataURL())}
								ready={() => drawCanvas(cropContainer.current.cropper.getCroppedCanvas().toDataURL())}
							/>
						</div>
						{!imageData && <>
							<Spacing mt={!isMobile ? 15 : 30}/>
							<Button title={t('userapp.fbPicture')} onClick={fbUpload} icon={FacebookIcon} loading={facebookLoading}
								inputStyle={{ flex: 1, borderRadius: theme.borderRadius.card, backgroundColor: '#3b5998' }}/>
						</>}
					</div>
				</div>
				<Spacing mt={!isMultiFrame && imageData ? 50 : 30}/>
				{!imageData ? null : <>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Button onClick={downloadImage}
							title={campaign.multiLanguage && currentLanguage.downloadTitle ? currentLanguage.downloadTitle : t('userapp.downloadImage')}/>
						<Spacing mr={20}/>
						<div onClick={reset} style={{ backgroundColor: theme.colors.primary, width: 60, cursor: 'pointer', display: 'flex',
							alignItems: 'center', justifyContent: 'center', borderRadius: theme.borderRadius.input }}>
							<img src={ResetIcon} width={18}/>
						</div>
					</div>
				</>}
				{!downloadData ? null : <>
					<Spacing mt={30}/>
					<img src={downloadData} style={{ maxWidth: '80%', aspectRatio: "1" }}/>
				</>}
				<Spacing mt={!isMultiFrame && imageData && !downloadData ? 40 : 20}/>
				{currentLanguage.campaignCredit ? <>
					<span style={{ textAlign: 'center', maxWidth: !isMobile ? '80%' : '90%' }}>{currentLanguage.campaignCredit}</span>
					<Spacing mt={!isMultiFrame && !downloadData ? 25 : 15}/>
				</> : null}
				<span style={{ textAlign: 'center' }}>
					{t('userapp.callToAction1')}
					<br/>
					<Link to='/account/signup' style={{ color: theme.colors.textSecondary, textDecoration: 'none' }}>
						{t('userapp.callToAction2')}
					</Link>
					&nbsp;
					{t('userapp.callToAction3')}
				</span>
				<Spacing m={10}/>
			</> : null}
        </div>
	);

}