import React from 'react';
import { useTheme } from '../utils';

export default function LoadingElm () {

	//Get theme
	const theme = useTheme();
    
    //Set variables
    const color = theme ? theme.colors.primary : '#df2e2e';

	return (
		<div className="loading-elm">
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
            <div style={{ background: color }}></div>
        </div>
	);

}