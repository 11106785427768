import React from 'react';

export default function Spacing ({ m, mt, mr, mb, ml }) {

    const style = {};
    if (m) style.margin = m;
    if (mt) style.marginTop = mt;
    if (mr) style.marginRight = mr;
    if (mb) style.marginBottom = mb;
    if (ml) style.marginLeft = ml;

	return <div style={style}></div>;

}