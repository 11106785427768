import React from 'react';
import ReactDOM from "react-dom";
import Root from './navigation/Root';
import { initLocalization } from './localization';
import './index.css';
import './loading.css';
import 'cropperjs/dist/cropper.css';

//Initialize localization
initLocalization();

ReactDOM.render(<Root/>, document.getElementById("root"));