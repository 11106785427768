import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../utils';
import { Button, Spacing } from '../components';
import ProblemIcon from '../assets/svg/problem.svg';

export default function Error ({ message, callback }) {

	//Get translations
	const { t } = useTranslation();
	//Get theme
	const theme = useTheme();

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular, overflow: 'auto' }}>
            <img src={ProblemIcon} width={100}/>
			<Spacing mt={20}/>
			<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
			<Spacing mb={5}/>
			<span style={{ color: theme.colors.textSecondary }}>{message}</span>
			<Spacing mb={20}/>
			<Button title={t('common.tryAgain')} onClick={callback}/>
        </div>
	);

}