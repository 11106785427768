import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useToasts } from 'react-toast-notifications';
import { useTheme, useUser } from '../utils';
import { API_URL } from '../config';
import { Spacing, TextField, Button } from '../components';
import * as EmailValidator from 'email-validator';
import FacebookIcon from '../assets/svg/facebook.svg';
import ArrowBackIcon from '../assets/svg/arrowLeft.svg';

export default function Signup () {

	//Used hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const { setUser } = useUser();
	const { addToast } = useToasts();
	const isMobile = useMediaQuery({ query: '(max-height: 580px)' });

	//Used state
	const [ actionLoading, setActionLoading ] = useState(false);
	const [ facebookLoading, setFacebookLoading ] = useState(false);
	const [ fbSignup, setFBSignup ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ fb, setFB ] = useState(null);

	//Used methods
	const emailHandle = useCallback((value) => setEmail(value), []);
	const passwordHandle = useCallback((value) => setPassword(value), []);
	const confirmPasswordHandle = useCallback((value) => setConfirmPassword(value), []);

	const signup = () => {
		if (!email || !password || !confirmPassword) {
			addToast(t('signup.missingErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (!EmailValidator.validate(email)) {
			addToast(t('signup.emailValidErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (password.length < 8) {
			addToast(t('signup.passwordCountErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (password !== confirmPassword) {
			addToast(t('signup.passwordMismatchErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		}
		setActionLoading(true);
		fetch(API_URL + '/signup', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password, fb })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.token) localStorage.setItem('token', res.token);
			if (res.user) setUser(res.user);
			navigate('/account');
		})
		.catch((err) => {
			if (err.message === 'EMAIL_EXISTS') addToast(t('signup.emailExistErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			else addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular });
			setActionLoading(false);
		});
	}

	const Promisfy = (func, param=null, postParam=null) => {
		return new Promise((resolve) => param ? func(param, resolve) : postParam ? func(resolve, postParam) : func(resolve));
	}

	const signupFacebook = async () => {
		setFacebookLoading(true);
		const statusResponse = await Promisfy(FB.getLoginStatus);
		if (statusResponse.status === 'connected') await Promisfy(FB.logout);
		const loginResponse = await Promisfy(FB.login, null, { scope: 'public_profile,email', auth_type: 'rerequest' });
		if (loginResponse.status === 'connected') {
			const fbResponse = await Promisfy(FB.api, '/me?fields=id,email,name');
			if (fbResponse.email) {
				const rawApiResponse = await fetch(API_URL + '/fbhandle', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ email: fbResponse.email })
				});
				const apiResponse = await rawApiResponse.json();
				if (apiResponse.error) {
					if (apiResponse.error.message === 'EMAIL_EXISTS') {
						addToast(t('signup.emailExistErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
					} else {
						addToast(t('common.error') + apiResponse.error.message, { appearance: 'error', fontFamily: theme.fonts.regular });
					}
				} else {
					setFB(loginResponse.authResponse);
					setEmail(fbResponse.email);
					setFBSignup(true);
				}
			} else {
				addToast(t('signup.fbEmailErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			}
		} else {
			addToast(t('signup.fbSignupErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
		}
		setFacebookLoading(false);
	}

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center',
			justifyContent: !isMobile ? 'center' : undefined, overflow: 'auto',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
			{!fbSignup && <>
				<Link to="/account" style={{ position: 'absolute', top: 30, left: 40 }}><img src={ArrowBackIcon} width={12}/></Link>
			</>}
            <h1 style={{ fontFamily: theme.fonts.bold, marginTop: !isMobile ? undefined : 50 }}>
				{!fbSignup ? t('signup.title') : t('signup.setPassword')}
			</h1>
			<Spacing mt={30}/>
			{!fbSignup && <>
				<TextField onChange={emailHandle} value={email} type="email" placeholder={t('signup.email')}/>
				<Spacing mt={15}/>
			</>}
			<TextField onChange={passwordHandle} value={password} type="password" placeholder={t('signup.password')}/>
			<Spacing mt={15}/>
			<TextField onChange={confirmPasswordHandle} value={confirmPassword} type="password"
				placeholder={t('signup.confirmPassword')} onEnter={signup}/>
			<Spacing mt={25}/>
			<Button title={t('signup.signup')} onClick={signup} loading={actionLoading}/>
			{!fbSignup && <>
				<Spacing mt={30}/>
				<div style={{ width: 340, maxWidth: '80vw', minHeight: 1, backgroundColor: theme.colors.secondary }}></div>
				<Spacing mt={20}/>
				<b>{t('signup.or')}</b>
				<Spacing mt={20}/>
				<Button title={t('signup.connectFacebook')} onClick={signupFacebook} icon={FacebookIcon} loading={facebookLoading}
					inputStyle={{ backgroundColor: '#3b5998' }}/>
			</>}
			<Spacing m={10}/>
        </div>
	);

}