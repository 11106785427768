import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useToasts } from 'react-toast-notifications';
import { useTheme, useUser } from '../utils';
import { API_URL } from '../config';
import { Spacing, TextField, Button } from '../components';
import * as EmailValidator from 'email-validator';
import LogoDark from '../assets/img/logo-dark.png';
import LogoLight from '../assets/img/logo-light.png';
import EmailIcon from '../assets/svg/email.svg';
import PasswordIcon from '../assets/svg/password.svg';
import FacebookIcon from '../assets/svg/facebook.svg';

export default function Login () {

	//Used hooks
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const { setUser } = useUser();
	const { addToast } = useToasts();
	const isMobile = useMediaQuery({ query: '(max-height: 620px)' });

	//Used state
	const [ actionLoading, setActionLoading ] = useState(false);
	const [ facebookLoading, setFacebookLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');

	//Used methods
	const emailHandle = useCallback((value) => setEmail(value), []);
	const passwordHandle = useCallback((value) => setPassword(value), []);

	const login = () => {
		if (!email || !password) {
			addToast(t('login.missingErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (!EmailValidator.validate(email)) {
			addToast(t('login.emailValidErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (password.length < 8) {
			addToast(t('login.passwordCountErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		}
		setActionLoading(true);
		fetch(API_URL + '/login', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.token) localStorage.setItem('token', res.token);
			if (res.user) setUser(res.user);
			navigate('/account');
		})
		.catch((err) => {
			if (err.message === 'INVALID_USER') addToast(t('login.userInvalidErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			else addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular });
			setActionLoading(false);
		});
	}

	const Promisfy = (func, param=null, postParam=null) => {
		return new Promise((resolve) => param ? func(param, resolve) : postParam ? func(resolve, postParam) : func(resolve));
	}

	const loginFacebook = async () => {
		setFacebookLoading(true);
		const statusResponse = await Promisfy(FB.getLoginStatus);
		if (statusResponse.status === 'connected') await Promisfy(FB.logout);
		const loginResponse = await Promisfy(FB.login, null, { scope: 'public_profile,email', auth_type: 'rerequest' });
		if (loginResponse.status === 'connected') {
			const fbResponse = await Promisfy(FB.api, '/me?fields=id,email,name');
			if (fbResponse.email) {
				const rawApiResponse = await fetch(API_URL + '/fbhandle', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ email: fbResponse.email, authResponse: loginResponse.authResponse })
				});
				const apiResponse = await rawApiResponse.json();
				if (apiResponse.error) {
					if (apiResponse.error.message === 'ACCOUNT_INVALID') {
						addToast(t('login.fbAccountErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
					} else if (apiResponse.error.message === 'LINK_INVALID') {
						addToast(t('login.fbLinkErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
					} else {
						addToast(t('common.error') + apiResponse.error.message, { appearance: 'error', fontFamily: theme.fonts.regular });
					}
				} else {
					if (apiResponse.token) localStorage.setItem('token', apiResponse.token);
					if (apiResponse.user) setUser(apiResponse.user);
					navigate('/account');
				}
			} else {
				addToast(t('login.fbEmailErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			}
		} else {
			addToast(t('login.fbLoginErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
		}
		setFacebookLoading(false);
	}

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center',
			justifyContent: !isMobile ? 'center' : undefined, overflow: 'auto',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
            <Link to="/" style={{ marginTop: !isMobile ? undefined : 50 }}>
				<img src={theme.name === 'dark' ? LogoLight : LogoDark} height={40}/>
			</Link>
			<Spacing mt={70}/>
			<TextField onChange={emailHandle} value={email} type="email" placeholder={t('login.email')} icon={EmailIcon}/>
			<Spacing mt={15}/>
			<TextField onChange={passwordHandle} value={password} type="password" placeholder={t('login.password')}
				icon={PasswordIcon} onEnter={login}/>
			<Spacing mt={25}/>
			<Button title={t('login.login')} onClick={login} loading={actionLoading}/>
			<Spacing mt={10}/>
			<span>
				{t('login.noAccountMsg')}&nbsp;
				<Link style={{ textDecoration: 'none', color: theme.colors.textSecondary }} to="/account/signup">{t('login.signup')}</Link>
			</span>
			<Spacing mt={5}/>
			<Link style={{ textDecoration: 'none', color: theme.colors.textPrimary }} to="/account/forgot">{t('login.forgotMsg')}</Link>
			<Spacing mt={30}/>
			<div style={{ width: 375, maxWidth: '80vw', minHeight: 1, backgroundColor: theme.colors.secondary }}></div>
			<Spacing mt={20}/>
			<b>{t('login.or')}</b>
			<Spacing mt={20}/>
			<Button title={t('login.connectFacebook')} onClick={loginFacebook} icon={FacebookIcon} loading={facebookLoading}
				inputStyle={{ backgroundColor: '#3b5998' }}/>
			<Spacing m={10}/>
        </div>
	);

}