import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useTheme, ImageUpload } from '../utils';
import { API_URL } from '../config';
import { Spacing, Button, TextField, LoadingElm } from '../components';
import ProblemIcon from '../assets/svg/problem.svg';
import DeleteIcon from '../assets/svg/delete.svg';
import DuplicateIcon from '../assets/svg/duplicate.svg';
import AddIcon from '../assets/svg/add.svg';
import InfoSmallIcon from '../assets/svg/infoSmall.svg';
import CloseIcon from '../assets/svg/close.svg';
import QuestionIcon from '../assets/svg/question.svg';

//Videos channel url
const videoChannelUrl = 'https://www.youtube.com/channel/UCZA4/_ANCPZxaffbzSPvsdog';
//Language template
const languageTemplate = {
	languageTitle: '',
	previewTitle: '',
	browseTitle: '',
	cropTitle: '',
	downloadTitle: '',
	previewMsg: '',
	campaignTitle: '',
	campaignSubtitle: '',
	campaignCredit: '',
	frameImages: [],
	frameIndexes: []
};
//Upload size
const uploadSize = 512;
//Default download size
const defaultSize = 512;
//Min download size
const minSize = 256;
//Max image size
const maxImageSize = 1000000;
//Max document size
const maxDocSize = 16000000;

export default function Manage () {

	//Used hooks
	const location = useLocation();
	const { t } = useTranslation();
	const theme = useTheme();
	const { addToast } = useToasts();

	//Used parameters
	const urlParams = new URLSearchParams(location.search);

	//Set used state
	const [ id, setId ] = useState(urlParams.get('id'));
    const [ loading, setLoading ] = useState(!(!id));
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ error, setError ] = useState(null);
	const [ name, setName ] = useState('');
	const [ url, setUrl ] = useState('');
	const [ multiLanguage, setMultiLanguage ] = useState(false);
	const [ watermark, setWatermark ] = useState(false);
	const [ size, setSize ] = useState(defaultSize);
	const [ languages, setLanguages ] = useState([ { ...languageTemplate } ]);

	//Used variables
	const title = !id ? t('manage.createCampaign') : t('manage.editCampaign');

    //Create styles
	const styles = StyleSheet.create({
		input: {
			':focus': {
				outline: 'none'
			},
			'::placeholder': {
				color: theme.colors.inputPlaceholder
			}
		}
	});

	//Used methods
	const gotoHelp = () => {
		window.open(videoChannelUrl);
	}

	const changeMultiLanguage = (value) => {
		if (languages.length > 1 && !value) {
			if (confirm(t('manage.multiLanguageMsg'))) {
				remove(-1);
				setMultiLanguage(value);
			}
		} else {
			setMultiLanguage(value);
		}
	}

	const changeLanguageItem = (index, key, value) => {
		const tmp = [ ...languages ];
		tmp[index][key] = value;
		setLanguages(tmp);
	}

	const addLanguage = () => {
		const tmp = [ ...languages, { ...languageTemplate } ];
		setLanguages(tmp);
	}

	const duplicate = (index) => {
		const tmp = [ ...languages, { ...languages[index] } ];
		setLanguages(tmp);
	}

	const remove = (index) => {
		const tmp = [ ...languages ];
		if (index === -1) {
			tmp.splice(1, tmp.length - 1);
			setLanguages(tmp);
		} else if (confirm(t('common.confirmMsg'))) {
			tmp.splice(index, 1);
			setLanguages(tmp);
		}
	}

	const infoImage = () => {
		addToast(t('manage.infoMsg'), { appearance: 'info', fontFamily: theme.fonts.regular });
	}

	const addImage = (index) => {
		ImageUpload(uploadSize, uploadSize, 'png', true)
		.then((base64) => {
			if (base64.length > maxImageSize) throw new Error('FILE_SIZE_INVALID');
			const tmp = [ ...languages ];
			const tmpFrame = [ ...tmp[index].frameImages ];
			tmpFrame.push(base64);
			tmp[index].frameImages = tmpFrame;
			const tmpIndexes = new Array(tmpFrame.length).fill(undefined).map((_, i) => i);
			tmp[index].frameIndexes = tmpIndexes;
			setLanguages(tmp);
		}).catch((err) => {
			if (err.message === 'FILE_SIZE_INVALID') {
				const maxSizeMB = parseInt(maxImageSize / 1000000);
				addToast(t('manage.fileSizeErr').replace('%s', maxSizeMB.toString()), { appearance: 'error', fontFamily: theme.fonts.regular });
			} else {
				addToast(t('manage.fileTypeErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			}
		});
	}

	const removeImage = (langIndex, frameIndex) => {
		const tmp = [ ...languages ];
		const tmpFrame = [ ...tmp[langIndex].frameImages ];
		tmpFrame.splice(frameIndex, 1);
		tmp[langIndex].frameImages = tmpFrame;
		const tmpIndexes = new Array(tmpFrame.length).fill(undefined).map((_, i) => i);
		tmp[langIndex].frameIndexes = tmpIndexes;
		setLanguages(tmp);
	}

	const save = () => {
		//Check download size
		if (!size || size < minSize) {
			addToast(t('manage.sizeErr').replaceAll('%s', minSize.toString()), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		}
		//Check missing data
		for (let i = 0; i < languages.length; i++) {
			const lang = languages[i];
			if (!lang.campaignTitle || (multiLanguage && !lang.languageTitle)) {
				addToast(t('manage.missingErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				return;
			}
			if (lang.frameImages.length === 0) {
				addToast(t('manage.missingFramesErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				return;
			}
		}
		//Check limit
		const maxSizeMB = parseInt(maxDocSize / 1000000);
		const params = { name, multiLanguage, watermark, languages, size: parseInt(size) };
		const body = JSON.stringify({ _id: id ? id : undefined, params });
		if (body.length > maxDocSize) {
			addToast(t('manage.docSizeErr').replace('%s', maxSizeMB.toString()), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		}
		//Save
		const method = id ? 'PUT' : 'POST';
		setSaveLoading(true);
		fetch(API_URL + '/campaign', {
			method, body,
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.url) setUrl(window.location.origin + '/' + res.url);
			if (res._id) setId(res._id);
			addToast(t('manage.saveSuccess'), { appearance: 'success', fontFamily: theme.fonts.regular });
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setSaveLoading(false));
	}

	const fetchCampaign = () => {
		if (id) {
			setLoading(true);
			setError(null);
			fetch(API_URL + '/campaign?_id=' + id)
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
				if (!res.campaign) throw new Error(t('manage.idNotValidErr'));
				else {
					setName(res.campaign.name);
					setUrl(window.location.origin + '/' + res.campaign.url);
					setMultiLanguage(res.campaign.multiLanguage);
					setWatermark(res.campaign.watermark);
					setSize(res.campaign.size.toString());
					setLanguages(res.campaign.languages);
				}
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));
		}
	}

	//Used effect
	useEffect(fetchCampaign, []);

	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', width: '80%' }}>
			<Spacing mt={40}/>
			<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%' }}>
				<h1 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{title}</h1>
				<div style={{ flex: 1 }}></div>
				<Button outline title={t('manage.helpVideos')} inputStyle={{ borderColor: '#019267', color: '#019267' }}
					icon={QuestionIcon} onClick={gotoHelp}/>
			</div>
			<Spacing mt={40}/>
			{loading ? <>
				<div style={{ flex: 1 }}></div>
				<LoadingElm/>
				<div style={{ flex: 1 }}></div>
			</> : error ? <>
				<div style={{ flex: 1 }}></div>
				<img src={ProblemIcon} width={100}/>
				<Spacing mt={20}/>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
				<Spacing mb={5}/>
				<span style={{ color: theme.colors.textSecondary }}>{error}</span>
				<Spacing mb={20}/>
				<Button title={t('common.tryAgain')} onClick={fetchCampaign}/>
				<div style={{ flex: 1 }}></div>
			</> : <>
				<div style={{ alignSelf: 'flex-start' }}>
					<TextField onChange={setName} value={name} type="text" placeholder={t('manage.campaignName')}/>
					<Spacing mt={15}/>
					<b>{t('manage.url')+': '}</b>
					<a href={url} target='_blank' style={{ color: theme.colors.textSecondary }}>{url}</a>
					<Spacing mt={10}/>
					<div style={{ display: 'flex' }}>
						<input type="checkbox" id="multiLanguage" checked={multiLanguage}
							onChange={(e) => changeMultiLanguage(e.target.checked)}/>
						<Spacing mr={5}/>
						<label htmlFor="multiLanguage">{t('manage.multiLanguage')}</label>
					</div>
					<Spacing mt={5}/>
					<div style={{ display: 'flex' }}>
						<input type="checkbox" id="watermark" checked={watermark} onChange={(e) => setWatermark(e.target.checked)}/>
						<Spacing mr={5}/>
						<label htmlFor="watermark">{t('manage.watermarkMsg')}</label>
					</div>
					<Spacing mt={15}/>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<span>{t('manage.downloadSize')}</span>
						<Spacing mr={15}/>
						<TextField onChange={setSize} value={size} type="number" placeholder={t('manage.size')}
							inputStyle={{ width: 80, height: 25, padding: '5px 10px' }}/>
						<Spacing mr={10}/>
						<span>{'(' + size.toString() + 'x' + size.toString() + ')'}</span>
					</div>
				</div>
				<Spacing mt={20}/>
				{multiLanguage && <>
					<Button onClick={addLanguage} title={t('manage.addLanguage')} containerStyle={{ alignSelf: 'flex-end' }}/>
					<Spacing mt={20}/>
				</>}
				{languages.map((item, i) => <div key={i} style={{ padding: '25px', width: '100%', boxSizing: 'border-box',
					overflow: 'auto', display: 'flex', marginBottom: i < languages.length - 1 ? 20 : undefined,
					backgroundColor: theme.colors.card, borderRadius: theme.borderRadius.card, boxShadow: theme.shadow.card }}>
					<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
						
						<TextField type="text" placeholder={t('manage.campaignTitle')} inputStyle={{ flex: 1,
							borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
							containerStyle={{ maxWidth: 'inherit' }}
							value={item.campaignTitle} onChange={(value) => changeLanguageItem(i, 'campaignTitle', value)}/>
						<Spacing mt={10}/>
						<textarea className={css(styles.input)} placeholder={t('manage.campaignSubtitle')} cols={16} rows={4} style={{
							flex: 1, border: 'none', fontSize: 'inherit', fontFamily: 'inherit', padding: '15px 20px',
							borderStyle: 'solid', borderWidth: 2, borderRadius: theme.borderRadius.card, borderColor: theme.colors.secondary,
							backgroundColor: theme.colors.input, color: theme.colors.textPrimary
						}} value={item.campaignSubtitle} onChange={(e) => changeLanguageItem(i, 'campaignSubtitle', e.target.value)}/>
					</div>
					<Spacing mr={20}/>
					<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
						<TextField type="text" placeholder={t('manage.creditMsg')} inputStyle={{ flex: 1,
							borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
							containerStyle={{ maxWidth: 'inherit' }} value={item.campaignCredit}
							onChange={(value) => changeLanguageItem(i, 'campaignCredit', value)}/>
						<Spacing mt={10}/>
						{multiLanguage && <>
							<TextField type="text" placeholder={t('manage.languageMsg')} inputStyle={{ flex: 1,
								borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
								containerStyle={{ maxWidth: 'inherit' }} value={item.languageTitle}
								onChange={(value) => changeLanguageItem(i, 'languageTitle', value)}/>
							<Spacing mt={10}/>
							<TextField type="text" placeholder={t('userapp.preview')} inputStyle={{ flex: 1,
								borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
								containerStyle={{ maxWidth: 'inherit' }} value={item.previewTitle}
								onChange={(value) => changeLanguageItem(i, 'previewTitle', value)}/>
							<Spacing mt={10}/>
							<TextField type="text" placeholder={t('userapp.browseImage')} inputStyle={{ flex: 1,
								borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
								containerStyle={{ maxWidth: 'inherit' }} value={item.browseTitle}
								onChange={(value) => changeLanguageItem(i, 'browseTitle', value)}/>
							<Spacing mt={10}/>
							<TextField type="text" placeholder={t('userapp.crop')} inputStyle={{ flex: 1,
								borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
								containerStyle={{ maxWidth: 'inherit' }} value={item.cropTitle}
								onChange={(value) => changeLanguageItem(i, 'cropTitle', value)}/>
							<Spacing mt={10}/>
							<TextField type="text" placeholder={t('userapp.downloadImage')} inputStyle={{ flex: 1,
								borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
								containerStyle={{ maxWidth: 'inherit' }} value={item.downloadTitle}
								onChange={(value) => changeLanguageItem(i, 'downloadTitle', value)}/>
							<Spacing mt={10}/>
							<TextField type="text" placeholder={t('userapp.previewMsg')} inputStyle={{ flex: 1,
								borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.secondary, boxShadow: '0 0 0' }}
								containerStyle={{ maxWidth: 'inherit' }} value={item.previewMsg}
								onChange={(value) => changeLanguageItem(i, 'previewMsg', value)}/>
							<Spacing mt={10}/>
						</>}
						<div style={{ display: 'flex' }}>
							<span>{t('manage.frameImages')}</span>
							<Spacing mr={10}/>
							<div style={{ flex: 1 }}></div>
							<img onClick={infoImage} src={InfoSmallIcon} height={20} style={{ cursor: 'pointer' }}/>
							<Spacing mr={10}/>
							<img onClick={() => addImage(i)} src={AddIcon} height={20} style={{ cursor: 'pointer' }}/>
						</div>
						<Spacing mt={5}/>
						<div style={{ flex: 1, display: 'flex' }}>
							{item.frameImages.map((imgItem, j) => <div key={j} style={{ width: 80, height: 80, position: 'relative',
								marginRight: j < item.frameImages.length - 1 ? 15 : undefined }}>
									<img src={imgItem} width="100%" height="100%"/>
									<div style={{ width: 25, height: 25, borderRadius: 15, backgroundColor: theme.colors.card,
										boxShadow: theme.shadow.card, display: 'flex', alignItems: 'center', justifyContent: 'center',
										cursor: 'pointer', position: 'absolute', right: 0, top: 0 }} onClick={() => removeImage(i, j)}>
										<img src={CloseIcon} height={12}/>
									</div>
							</div>)}
						</div>
					</div>
					{multiLanguage && <>
						<Spacing mr={20}/>
						<div style={{ display: 'flex' }}>
							<img onClick={() => duplicate(i)} src={DuplicateIcon} height={18} style={{ cursor: 'pointer' }}/>
							{i > 0 && <>
								<Spacing mr={15}/>
								<img onClick={() => remove(i)} src={DeleteIcon} height={18} style={{ cursor: 'pointer' }}/>
							</>}
						</div>
					</>}
				</div>)}
				<Spacing mt={30}/>
				<Button title={t('manage.save')} onClick={save} loading={saveLoading}/>
			</>}
			<Spacing m={15}/>
		</div>
	);

}