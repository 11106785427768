import React from 'react';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../utils';
import { Spacing } from '../components';
import InfoIcon from '../assets/svg/info.svg';
import LogoDark from '../assets/img/logo-dark.png';
import LogoLight from '../assets/img/logo-light.png';

export default function NotFoundWithLogo () {

	//Get translations
	const { t } = useTranslation();
	//Get theme
	const theme = useTheme();
	//Get media query
	const isMobile = useMediaQuery({ query: '(max-height: 430px)' });

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center',
			justifyContent: !isMobile ? 'center' : undefined, overflow: 'auto',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
			<Link to="/account" style={{ marginTop: !isMobile ? undefined : 50 }}>
			<img src={theme.name === 'dark' ? LogoLight : LogoDark} height={40}/>
			</Link>
			<Spacing mt={70}/>
			<img src={InfoIcon} width={100}/>
			<Spacing mt={30}/>
			<h1 style={{ margin: 0 }}>{t('common.404')}</h1>
			<Spacing mt={5}/>
			<span style={{ color: theme.colors.textSecondary }}>{t('common.notFound')}</span>
			<Spacing m={10}/>
		</div>
	);

}