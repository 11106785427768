import Home from './Home';
import UserApp from './UserApp';
import Loading from './Loading';
import Error from './Error';
import NotFoundWithLogo from './NotFoundWithLogo';
import NotFound from './NotFound';
import Login from './Login';
import Signup from './Signup';
import Forgot from './Forgot';
import Campaigns from './Campaigns';
import Manage from './Manage';
import Stats from './Stats';
import Profile from './Profile';
import Users from './Users';
import DataDeletion from './DataDeletion';
import PrivacyPolicy from './PrivacyPolicy';

export { Home, UserApp, Loading, Error, DataDeletion, PrivacyPolicy };

export const AUTH_STACK = {
    index: { key: 'login', screen: Login },
    notFound: NotFoundWithLogo,
    screens: {
        login: Login,
        signup: Signup,
        forgot: Forgot
    }
};

export const USER_STACK = {
    index: { key: 'campaigns', screen: Campaigns },
    notFound: NotFound,
    screens: {
        campaigns: Campaigns,
        manage: Manage,
        stats: Stats,
        profile: Profile
    },
    params: { stats: [ ':id' ] }
};

export const ADMIN_STACK = {
    index: { key: 'users', screen: Users },
    notFound: NotFound,
    screens: {
        users: Users,
        campaigns: Campaigns,
        manage: Manage,
        stats: Stats,
        profile: Profile
    },
    params: { stats: [ ':id' ] }
};