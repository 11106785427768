import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useTheme } from '../utils';
import { API_URL } from '../config';
import { Spacing, Button, TextField, LoadingElm, Select } from '../components';
import ReactLoading from "react-loading";
import InfoIcon from '../assets/svg/info.svg';
import ProblemIcon from '../assets/svg/problem.svg';
import SearchIcon from '../assets/svg/search.svg';
import DeleteIcon from '../assets/svg/delete.svg';
import FrameIcon from '../assets/svg/frame.svg';
import MakeAdminIcon from '../assets/svg/makeAdmin.svg';
import RemoveAdminIcon from '../assets/svg/removeAdmin.svg';
import SearchEnterIcon from '../assets/svg/searchEnter.svg';
import SearchCloseIcon from '../assets/svg/searchClose.svg';

export default function Users () {

	//Used hooks
	const { t } = useTranslation();
	const theme = useTheme();
	const { addToast } = useToasts();
	const location = useLocation();

	//Used parameters
	const urlParams = new URLSearchParams(location.search);
	const pageParam = urlParams.get('page') ? parseInt(urlParams.get('page')) : 1;
	const searchParam = urlParams.get('search') ? urlParams.get('search') : '';
	const searchTypes = [{ value: 'email', label: t('users.email') }, { value: 'url', label: t('users.campaignUrl') }];
	const sortTypes = [{ value: 'latestUser', label: t('users.latestUser') }, { value: 'latestCampaign', label: t('users.latestCampaign') },
					   { value: 'campaignsTotal', label: t('users.campaignsTotal') }, { value: 'campaignViews', label: t('users.campaignViews') },
					   { value: 'campaignDownloads', label: t('users.campaignDownloads') }];

	//Set used state
    const [ loading, setLoading ] = useState(true);
    const [ searchLoading, setSearchLoading ] = useState(false);
    const [ exportLoading, setExportLoading ] = useState(false);
    const [ statsLoading, setStatsLoading ] = useState(false);
    const [ clearLoading, setClearLoading ] = useState(false);
    const [ adminLoading, setAdminLoading ] = useState(null);
    const [ removeLoading, setRemoveLoading ] = useState(null);
    const [ error, setError ] = useState(null);
	const [ items, setItems ] = useState([]);
	const [ count, setCount ] = useState(0);
	const [ page, setPage ] = useState(pageParam > 0 ? pageParam : 1);
	const [ search, setSearch ] = useState(searchParam);
	const [ searchType, setSearchType ] = useState(searchTypes[0].value);
	const [ sortType, setSortType ] = useState(sortTypes[0].value);

	//Used variables
	const limit = 10;
	const pages = new Array(Math.ceil(count / limit)).fill(undefined).map((_, i) => i + 1);
	const empty = items.length === 0;

	//Used methods
	const showStats = () => {
		setStatsLoading(true);
		fetch(API_URL + '/appstats', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			alert(t('users.totalUsers') + ': ' + res.count.users.toString() + '\n' + t('users.totalCampaigns') + ': ' + res.count.campaigns.toString());
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setStatsLoading(false));
	}

	const clearStats = () => {
		if (confirm(t('common.confirmMsg'))) {
			setClearLoading(true);
			fetch(API_URL + '/stats', {
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
			})
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
				addToast(t('common.done'), { appearance: 'success', fontFamily: theme.fonts.regular });
			})
			.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
			.finally(() => setClearLoading(false));
		}
	}

	const exportUsers = () => {
		if (confirm(t('users.exportMsg'))) {
			setExportLoading(true);
			fetch(API_URL + '/user?exp=true', {
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
			})
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
				if (res.items) {
					var data = `${t('users.id')}, ${t('users.email')}, ${t('users.dateCreated')}, ${t('users.totalCampaigns')}\n`;
					res.items.forEach((item) => {
						data += `${item._id}, ${item.email}, ${item.date}, ${item.totalCampaigns}\n`;
					});
					const encodedUri = encodeURI(data);
					const link = document.createElement("a");
					link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
					link.setAttribute("download", "export.csv");
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
			.finally(() => setExportLoading(false));
		}
	}

	const pageSelect = (value) => {
		setPage(value);
		fetchUsers(value);
	}

	const performSearch = () => {
		setPage(1);
		fetchUsers(1);
	}

	const clearSearch = () => {
		setSearch('');
		setPage(1);
		fetchUsers(1, '');
	}

	const sortTypeSelect = (value) => {
		setSortType(value);
		setPage(1);
		fetchUsers(1, null, value);
	}

	const remove = (index, _id) => {
		if (removeLoading !== null) return;
		setRemoveLoading(index);
		fetch(API_URL + '/user', {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ _id })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			const newItems = [ ...items ];
			newItems.splice(index, 1);
			setItems(newItems);
			setCount(count - 1);
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setRemoveLoading(null));
	}

	const changeAdmin = (index, _id, newAdminStatus) => {
		if (adminLoading !== null) return;
		setAdminLoading(index);
		fetch(API_URL + '/user', {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ _id, params: { admin: newAdminStatus } })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.user) {
				const newItems = [ ...items ];
				newItems[index] = res.user;
				setItems(newItems);
			}
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setAdminLoading(null));
	}

	const fetchUsers = (selectedPage=null, typedSearch=null, selectedSortType=null) => {
		const usedPage = selectedPage !== null ? selectedPage : page;
		const usedSearch = typedSearch !== null ? typedSearch : search;
		const usedSortType = selectedSortType !== null ? selectedSortType : sortType;
		setSearchLoading(true);
		setError(null);
		setItems([]);
		setCount(0);
		const searchParam = usedSearch ? '&search=' + usedSearch : '';
		fetch(API_URL + `/user?page=${usedPage}&limit=${limit}&searchType=${searchType}&sortType=${usedSortType}${searchParam}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.count) setCount(res.count);
			if (res.items) {
				if (res.items.length > 0 && res.items[0].user !== undefined) {
					setItems(res.items.map((item) => {
						const user = item.user[0];
						if (item.date !== undefined) user.campaignDate = item.date;
						if (item.views !== undefined) user.views = item.views;
						if (item.downloads !== undefined) user.downloads = item.downloads;
						return user;
					}));
				} else {
					setItems(res.items);
				}
			}
		})
		.catch((err) => setError(err.message))
		.finally(() => {
			setSearchLoading(false);
			setLoading(false);
		});
	}

	//Used effect
	useEffect(() => fetchUsers(), []);

	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', width: '80%' }}>
			<Spacing mt={40}/>
			<h1 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('users.title')}</h1>
			<Spacing mt={40}/>
			{loading ? <>
				<div style={{ flex: 1 }}></div>
				<LoadingElm/>
				<div style={{ flex: 1 }}></div>
				<Spacing m={20}/>
			</> : error ? <>
				<div style={{ flex: 1 }}></div>
				<img src={ProblemIcon} width={100}/>
				<Spacing mt={20}/>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
				<Spacing mb={5}/>
				<span style={{ color: theme.colors.textSecondary }}>{error}</span>
				<Spacing mb={20}/>
				<Button title={t('common.tryAgain')} onClick={() => fetchUsers()}/>
				<div style={{ flex: 1 }}></div>
				<Spacing m={20}/>
			</> : <>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
					<TextField placeholder={t('users.searchMsg')} value={search} onChange={setSearch} icon={SearchIcon}
						enterIcon={SearchEnterIcon} closeIcon={search ? SearchCloseIcon : undefined}
						onEnter={performSearch} onClose={clearSearch} containerStyle={{ marginBottom: 10 }}/>
					<span style={{ marginLeft: 20, marginRight: 10, marginBottom: 10 }}>{t('users.searchBy')}</span>
					<Select onChange={setSearchType} value={searchType} items={searchTypes} withLabel
						containerStyle={{ marginBottom: 10 }} inputStyle={{ width: 160, padding: 15, fontFamily: theme.fonts.regular }}/>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap', width: '100%' }}>
					{search === '' && <>
						<span style={{ marginRight: 10, marginBottom: 10 }}>{t('users.sortBy')}</span>
						<Select onChange={sortTypeSelect} value={sortType} items={sortTypes} withLabel
							containerStyle={{ marginBottom: 10 }} inputStyle={{ width: 200, padding: 15, fontFamily: theme.fonts.regular }}/>
						<div style={{ marginRight: 10, flex: 1 }}></div>
					</>}
					<Button outline onClick={clearStats} title={t('users.clearStats')} containerStyle={{ marginBottom: 10 }}
						loading={clearLoading} loadingColor={theme.colors.textSecondary}
						inputStyle={{ borderColor: theme.colors.textSecondary, color: theme.colors.textSecondary }}/>
					<Spacing mr={10}/>
					<Button onClick={showStats} outline title={t('users.showStats')} containerStyle={{ marginBottom: 10 }}
						loading={statsLoading}/>
					<Spacing mr={10}/>
					<Button onClick={exportUsers} title={t('users.exportUsers')} containerStyle={{ marginBottom: 10 }}
						loading={exportLoading}/>
				</div>
				{searchLoading ? <>
					<ReactLoading type="spinningBubbles" width={50} height={50} color={theme.colors.primary}/>
				</> : empty ? <>
					<img src={InfoIcon} width={100}/>
					<Spacing mt={20}/>
					<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('users.emptyMsg')}</h3>
				</> : <>
					{items.map((item, i) => <div key={item._id} style={{ padding: '25px', width: '100%', boxSizing: 'border-box',
						backgroundColor: theme.colors.card, borderRadius: theme.borderRadius.card, boxShadow: theme.shadow.card,
						marginBottom: 20, display: 'flex', alignItems: 'center', overflow: 'auto' }}>
						<div style={{ flex: 1 }}>
							<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{item.email}</h3>
							<br/>
							<b>{t('users.dateCreated') + ': '}</b>
							<span style={{ color: theme.colors.textSecondary }}>{new Date(item.date).toLocaleDateString()}</span>
							<br/>
							<b>{t('users.totalCampaigns') + ': '}</b>
							<span style={{ color: theme.colors.textSecondary }}>{item.totalCampaigns.toString()}</span>
							{item.campaignDate !== undefined && <>
								<br/>
								<b>{t('users.campaignDateMsg') + ': '}</b>
								<span style={{ color: theme.colors.textSecondary }}>{new Date(item.campaignDate).toLocaleDateString()}</span>
							</>}
							{item.views !== undefined && <>
								<br/>
								<b>{t('users.campaignViewsMsg') + ': '}</b>
								<span style={{ color: theme.colors.textSecondary }}>{item.views.toString()}</span>
							</>}
							{item.downloads !== undefined && <>
								<br/>
								<b>{t('users.campaignDownloadsMsg') + ': '}</b>
								<span style={{ color: theme.colors.textSecondary }}>{item.downloads.toString()}</span>
							</>}
						</div>
						<Spacing mr={5}/>
						<div style={{ display: 'flex' }}>
							{adminLoading === i ? (
								<ReactLoading type="spinningBubbles" width={14} height={14} color={theme.colors.textSecondary}/>
							) : (
								<img onClick={() => changeAdmin(i, item._id, !item.admin)} height={18} style={{ cursor: 'pointer' }}
									src={!item.admin ? MakeAdminIcon : RemoveAdminIcon}/>
							)}
							<Spacing mr={15}/>
							<Link to={"/account/campaigns?id=" + item._id + '&email=' + item.email}>
								<img src={FrameIcon} height={18} style={{ cursor: 'pointer' }}/>
							</Link>
							<Spacing mr={15}/>
							{removeLoading === i ? (
								<ReactLoading type="spinningBubbles" width={14} height={14} color={theme.colors.textSecondary}/>
							) : (
								<img src={DeleteIcon} height={18} style={{ cursor: 'pointer' }} onClick={() => {
									if (confirm(t('common.confirmMsg'))) remove(i, item._id);
								}}/>
							)}
						</div>
					</div>)}
					<Spacing mt={10}/>
					<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
						{pages.length > 1 && pages.map((item, i) => <div key={i} onClick={() => pageSelect(item)} style={{ width: 50,
							height: 50, borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center',
							cursor: 'pointer', marginBottom: 10, marginRight: i < pages.length - 1 ? 10 : 0,
							...(item === page ? {
								backgroundColor: theme.colors.primary, color: theme.colors.textOnPrimary
							} : {
								borderStyle: 'solid', borderWidth: 2, boxSizing: 'border-box', borderColor: theme.colors.primary
							}) }}>
							{item.toString()}
						</div>)}
					</div>
				</>}
				<Spacing m={10}/>
			</>}
		</div>
	);

}