import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Spacing } from '../components';
import LogoDark from '../assets/img/logo-dark.png';

//Theme object
const theme = {
	colors: {
		background: '#fafafa',
		textPrimary: '#000000',
		textSecondary: '#7c7c7c'
	},
	fonts: {
		regular: 'Proxima Nova Regular, sans-serif',
		bold: 'Proxima Nova Bold, sans-serif'
	}
}

export default function PrivacyPolicy () {

	//Get translations
	const { t } = useTranslation();

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center',
			overflow: 'auto', backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
			<Spacing mt={50}/>
			<Link to="/"><img src={LogoDark} height={40}/></Link>
			<Spacing mt={50}/>
			<h1 style={{ margin: 0 }}>{t('privacyPolicy.title')}</h1>
			<Spacing mt={20}/>
			<span style={{ color: theme.colors.textSecondary, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg1')}</span>
			<Spacing mt={20}/>
			<b style={{ fontFamily: theme.fonts.bold, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg2Title')}</b>
			<span style={{ color: theme.colors.textSecondary, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg2')}</span>
			<Spacing mt={20}/>
			<b style={{ fontFamily: theme.fonts.bold, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg3Title')}</b>
			<span style={{ color: theme.colors.textSecondary, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg3')}</span>
			<Spacing mt={20}/>
			<b style={{ fontFamily: theme.fonts.bold, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg4Title')}</b>
			<span style={{ color: theme.colors.textSecondary, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg4')}</span>
			<Spacing mt={20}/>
			<b style={{ fontFamily: theme.fonts.bold, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg5Title')}</b>
			<span style={{ color: theme.colors.textSecondary, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg5')}</span>
			<Spacing mt={20}/>
			<b style={{ fontFamily: theme.fonts.bold, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg6Title')}</b>
			<span style={{ color: theme.colors.textSecondary, textAlign: 'center', maxWidth: '80%' }}>{t('privacyPolicy.msg6')}</span>
			<Spacing m={10}/>
		</div>
	);

}