import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useTheme } from '../utils';
import { API_URL } from '../config';
import { Spacing, Button, LoadingElm } from '../components';
import ProblemIcon from '../assets/svg/problem.svg';
import ArrowRightCirclePrimaryIcon from '../assets/svg/arrowRightCirclePrimary.svg';

//Default duration (days)
const DEFAULT_DAYS = 6;

export default function Stats () {

	//Used hooks
	const params = useParams();
	const { t } = useTranslation();
	const theme = useTheme();

	//Used 
	const fromDateDefault = new Date();
	fromDateDefault.setDate(fromDateDefault.getDate() - DEFAULT_DAYS);
	const toDateDefault = new Date();

	//Used state
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
	const [ campaign, setCampaign ] = useState(null);
	const [ stats, setStats ] = useState([]);
	const [ fromDate, setFromDate ] = useState(fromDateDefault.toISOString().substr(0, 10));
	const [ toDate, setToDate ] = useState(toDateDefault.toISOString().substr(0, 10));

	//Used variables
	const tableStyle = { borderStyle: 'solid', borderWidth: 1.5, borderColor: theme.colors.textSecondary, padding: 10 };

	//Used methods
	const fetchStats = (fetchCampaign=true) => {
		setLoading(true);
		setError(null);
		setStats([]);
		const promise = !fetchCampaign ? Promise.resolve() : fetch(API_URL + '/campaign?_id=' + params.id + '&no_images=true')
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (!res.campaign) throw new Error(t('stats.idNotValidErr'));
			else setCampaign(res.campaign);
		});
		promise.then(() => fetch(API_URL + `/stats?campaign_id=${params.id}&fromDateISO=${new Date(fromDate).toISOString()}&toDateISO=${new Date(toDate).toISOString()}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }
		}))
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.stats) setStats(res.stats);
		})
		.catch((err) => setError(err.message))
		.finally(() => setLoading(false));
	}

	//Used effect
	useEffect(() => fetchStats(), []);
	
	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', width: '80%' }}>
			<Spacing mt={40}/>
			{loading ? <>
				<div style={{ flex: 1 }}></div>
				<LoadingElm/>
				<div style={{ flex: 1 }}></div>
			</> : error ? <>
				<div style={{ flex: 1 }}></div>
				<img src={ProblemIcon} width={100}/>
				<Spacing mt={20}/>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
				<Spacing mb={5}/>
				<span style={{ color: theme.colors.textSecondary }}>{error}</span>
				<Spacing mb={20}/>
				<Button title={t('common.tryAgain')} onClick={() => fetchStats()}/>
				<div style={{ flex: 1 }}></div>
			</> : campaign ? <>
				<h1 style={{ margin: 0, fontFamily: theme.fonts.bold, textAlign: 'center' }}>{campaign.name}</h1>
				<Spacing mt={40}/>
				<div style={{ alignSelf: 'flex-start' }}>
					<b>{t('stats.url')+': '}</b>
					<span style={{ color: theme.colors.textSecondary }}>{window.location.origin + '/' + campaign.url}</span>
					<br/>
					<b>{t('stats.dateCreated')+': '}</b>
					<span style={{ color: theme.colors.textSecondary }}>{new Date(campaign.date).toLocaleDateString()}</span>
					<br/>
					<b>{t('stats.daysRunning')+': '}</b>
					<span style={{ color: theme.colors.textSecondary }}>{(() => {
						const now = new Date().getTime();
						const created = new Date(campaign.date).getTime();
						const diff = Math.round((now - created) / 86400000);
						return diff.toString();
					})()}</span>
				</div>
				<Spacing mt={20}/>
				<div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end', flexWrap: 'wrap' }}>
					<span>{t('stats.from')}</span>
					<Spacing mr={5}/>
					<input type="date" value={fromDate} onChange={(e) => e.target.value ? setFromDate(e.target.value) : null}/>
					<Spacing mr={15}/>
					<span>{t('stats.to')}</span>
					<Spacing mr={5}/>
					<input type="date" value={toDate} onChange={(e) => e.target.value ? setToDate(e.target.value) : null}/>
					<Spacing mr={15}/>
					<img onClick={() => fetchStats(false)} src={ArrowRightCirclePrimaryIcon} width={28} height={28} style={{ cursor: 'pointer' }}/>
				</div>
				<Spacing mt={20}/>
				<div style={{ padding: '25px', width: '100%', boxSizing: 'border-box', overflow: 'auto', display: 'flex', flexDirection: 'column',
					backgroundColor: theme.colors.card, borderRadius: theme.borderRadius.card, boxShadow: theme.shadow.card }}>
					<div>
						<b>{t('stats.totalCampaignViews') + ': '}</b>
						<span style={{ color: theme.colors.textSecondary }}>{campaign.views.toString()}</span>
						<br/>
						<b>{t('stats.totalFrameDownloads') + ': '}</b>
						<span style={{ color: theme.colors.textSecondary }}>{campaign.downloads.toString()}</span>
					</div>
					<Spacing mt={20}/>
					<table style={{ borderCollapse: 'collapse', ...tableStyle }}>
						<thead>
							<tr>
								{campaign.multiLanguage && <th style={tableStyle}>{t('stats.languages')}</th>}
								<th style={tableStyle}>{t('stats.views')}</th>
								<th style={tableStyle}>{t('stats.downloads')}</th>
							</tr>
						</thead>
						<tbody>
							{campaign.languages.map((item, i) => <tr key={i}>
								{campaign.multiLanguage && <td style={tableStyle} width="20%">{item.languageTitle}</td>}
								<td style={tableStyle} width="20%">
									<span>{stats.reduce((prev, statItem) => {
										return prev + (statItem.languages[i] ? statItem.languages[i].views : 0);
									}, 0)}</span>
								</td>
								<td style={tableStyle} width="60%">
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{item.frameIndexes && item.frameIndexes.map((frameElm, j) => <div key={j}
											style={{ display: 'flex', alignItems: 'center', marginBottom: j < item.frameIndexes.length - 1 ? 20 : 0 }}>
											<img width={40} height={40} src={API_URL + `/campaign?pic=${campaign.url}-${i}-${frameElm}`}/>
											<Spacing mr={10}/>
											<span>{stats.reduce((prev, statItem) => {
												return prev + (statItem.languages[i] && statItem.languages[i].downloads[frameElm] !== undefined ? statItem.languages[i].downloads[frameElm] : 0);
											}, 0)}</span>
										</div>)}
									</div>
								</td>
							</tr>)}
						</tbody>
					</table>
				</div>
			</> : null}
			<Spacing m={20}/>
		</div>
	);

}