import React, { createContext, useContext } from "react";

//Themes object
const themes = {
    light: {
        name: 'light',
        colors: {
            background: '#fafafa',
            primary: '#df2e2e',
            secondary: '#e5e5e5',
            textPrimary: '#000000',
            textSecondary: '#7c7c7c',
            textOnPrimary: '#ffffff',
            input: '#ffffff',
            inputPlaceholder: '#bcbcbc',
            card: '#ffffff'
        },
        borderRadius: {
            card: 15,
            input: 35
        },
        shadow: {
            card: '0 0 8px #00000019',
            input: '0 0 8px #00000019'
        },
        fonts: {
            regular: 'Proxima Nova Regular, sans-serif',
            bold: 'Proxima Nova Bold, sans-serif'
        }
    },
    dark: {
        name: 'dark',
        colors: {
            background: '#2b2b2b',
            primary: '#df2e2e',
            secondary: '#b0b0b0',
            textPrimary: '#ffffff',
            textSecondary: '#c9c9c9',
            textOnPrimary: '#ffffff',
            input: '#232323',
            inputPlaceholder: '#d3d3d3',
            card: '#232323'
        },
        borderRadius: {
            card: 15,
            input: 35
        },
        shadow: {
            card: '0 0 8px #00000019',
            input: '0 0 8px #00000019'
        },
        fonts: {
            regular: 'Proxima Nova Regular, sans-serif',
            bold: 'Proxima Nova Bold, sans-serif'
        }
    }
}

//Theme context
const ThemeContext = createContext();

//Theme provider
export const ThemeProvider = ({ children, theme }) => {
    const themeObj = theme === 'dark' ? themes.dark : themes.light;
    return <ThemeContext.Provider value={themeObj}>{children}</ThemeContext.Provider>;
}

//Theme hook function
export const useTheme = () => {
    return useContext(ThemeContext);
}