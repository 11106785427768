import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../utils';
import { Spacing } from '../components';
import InfoIcon from '../assets/svg/info.svg';

export default function NotFound () {

	//Get translations
	const { t } = useTranslation();
	//Get theme
	const theme = useTheme();

	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			<Spacing mt={30}/>
			<img src={InfoIcon} width={100}/>
			<Spacing mt={30}/>
			<h1 style={{ margin: 0 }}>{t('common.404')}</h1>
			<Spacing mt={5}/>
			<span style={{ color: theme.colors.textSecondary }}>{t('common.notFound')}</span>
			<Spacing m={10}/>
		</div>
	);

}