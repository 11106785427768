import React, { useState, useCallback } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useToasts } from 'react-toast-notifications';
import { useTheme, useUser } from '../../utils';
import { API_URL } from '../../config';
import { USER_STACK, ADMIN_STACK } from "../../pages";
import Logo from '../../assets/img/logo-light.png';
import ProfileIcon from '../../assets/svg/profile-light.svg';
import LogoutIcon from '../../assets/svg/logout.svg';
import ArrowLeftCircleIcon from '../../assets/svg/arrowLeftCircle.svg';

export default function UserStack ({ admin }) {

	//Used hooks
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const { user, setUser } = useUser();
	const theme = useTheme();
	const { addToast } = useToasts();
	const isMobile = useMediaQuery({ query: '(max-width: 680px)' });

	//Set used state
	const [ isProfilePage, setIsProfilePage ] = useState(false);

	//Get routes names
	const STACK = !admin ? USER_STACK : ADMIN_STACK;
	const routesNames = Object.keys(STACK.screens);

	//Used methods
	const isProfilePageHandle = useCallback((value) => setIsProfilePage(value), []);

	const goDefault = () => {
		navigate('/account');
		window.location.reload();
	}

	const goBack = () => {
		navigate(-1);
	}

	const goMenu = () => {
		if (!isProfilePage) {
			navigate('/account/profile');
		} else {
			fetch(API_URL + '/logout', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ _id: user._id })
			})
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
				localStorage.setItem('token', '');
				setUser(null);
				navigate('/account');
			})
			.catch((err) => {
				addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular });
			});
		}
	}

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'auto',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
			<div style={{ backgroundColor: theme.colors.primary, width: '100%' }}>
				<div style={{ width: '90%', margin: 'auto', padding: '30px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{location.pathname !== '/account' && location.pathname !== '/account/' + STACK.index.key ? <>
						<div onClick={goBack} style={{ cursor: 'pointer', height: 40 }}>
							<img src={ArrowLeftCircleIcon} height={40}/>
						</div>
						<div style={{ flex: 1 }}></div>
					</> : !isMobile ? <>
						<div style={{ minWidth: 40, height: 40 }}></div>
						<div style={{ flex: 1 }}></div>
					</> : null}
					<img src={Logo} height={40} style={{ cursor: 'pointer' }} onClick={goDefault}/>
					<div style={{ flex: 1 }}></div>
					{!isMobile ? (
						<a onClick={goMenu} style={{ cursor: 'pointer', color: theme.colors.textOnPrimary }}>
							{!isProfilePage ? t('common.profile') : t('common.logout')}
						</a>
					) : (
						<div onClick={goMenu} style={{ cursor: 'pointer' }}>
							<img src={!isProfilePage ? ProfileIcon : LogoutIcon} height={40} alt="ja"/>
						</div>
					)}
				</div>
			</div>
			<Routes>
				<Route index element={<STACK.index.screen />} />
				{routesNames.map((name) => {
					const Component = STACK.screens[name];
					const params = STACK.params[name] ? '/' + STACK.params[name].join('/') : '';
					return <Route key={name} path={"/" + name + params} element={<Component isProfilePageHandle={isProfilePageHandle} />} />;
				})}
				<Route path="*" element={<STACK.notFound />} />
			</Routes>
		</div>
	);

}