import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useTheme, useUser } from '../utils';
import { API_URL } from '../config';
import { languages } from '../localization';
import { Spacing, Button, TextField, Select } from '../components';
import * as EmailValidator from 'email-validator';
import FacebookIcon from '../assets/svg/facebook.svg';

export default function Profile ({ isProfilePageHandle }) {

	//Used hooks
	const { t } = useTranslation();
	const theme = useTheme();
	const { addToast } = useToasts();
	const { user, setUser } = useUser();

	//Used variables
	const langItems = Object.keys(languages).map((key) => { return { value: key, label: languages[key].name } });

	//Set used state
	const [ actionLoading, setActionLoading ] = useState(false);
	const [ facebookLoading, setFacebookLoading ] = useState(false);
	const [ email, setEmail ] = useState(user.email);
	const [ oldPassword, setOldPassword ] = useState('');
	const [ newPassword, setNewPassword ] = useState('');
	const [ confirmNewPassword, setConfirmNewPassword ] = useState('');
	const [ lang, setLang ] = useState(user.lang);
	const [ darkMode, setDarkMode ] = useState(user.theme === 'dark');

	//Used methods
	const emailHandle = useCallback((value) => setEmail(value), []);
	const oldPasswordHandle = useCallback((value) => setOldPassword(value), []);
	const newPasswordHandle = useCallback((value) => setNewPassword(value), []);
	const confirmNewPasswordHandle = useCallback((value) => setConfirmNewPassword(value), []);
	const langHandle = useCallback((value) => setLang(value), []);
	const darkModeHandle = useCallback((value) => setDarkMode(value), []);

	const save = () => {
		if (!email || (oldPassword && (!newPassword || !confirmNewPassword)) || (!oldPassword && (newPassword || confirmNewPassword))) {
			addToast(t('profile.missingErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (!EmailValidator.validate(email)) {
			addToast(t('profile.emailValidErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (oldPassword && newPassword.length < 8) {
			addToast(t('profile.passwordCountErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		} else if (oldPassword && newPassword !== confirmNewPassword) {
			addToast(t('profile.passwordMismatchErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			return;
		}
		const params = {};
		if (user.email !== email) params.email = email;
		if (oldPassword) params.password = newPassword;
		if (user.lang !== lang) params.lang = lang;
		if ((user.theme === 'dark') !== darkMode) params.theme = darkMode ? 'dark' : 'light';
		setActionLoading(true);
		const promise = !oldPassword ? Promise.resolve() : fetch(API_URL + '/checkpassword', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ password: oldPassword })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
		});
		promise.then(() => fetch(API_URL + '/user', {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ params })
		}))
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (user.lang !== lang) window.location.reload();
			else if (res.user) setUser(res.user);
			addToast(t('profile.saveSuccess'), { appearance: 'success', fontFamily: theme.fonts.regular });
		})
		.catch((err) => {
			if (err.message === 'INVALID_PASSWORD') addToast(t('profile.oldPasswordErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			else addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular })
		})
		.finally(() => setActionLoading(false));
	}

	const Promisfy = (func, param=null, postParam=null) => {
		return new Promise((resolve) => param ? func(param, resolve) : postParam ? func(resolve, postParam) : func(resolve));
	}

	const linkFacebook = async () => {
		setFacebookLoading(true);
		const statusResponse = await Promisfy(FB.getLoginStatus);
		if (statusResponse.status === 'connected') await Promisfy(FB.logout);
		const loginResponse = await Promisfy(FB.login, null, { scope: 'public_profile,email', auth_type: 'rerequest' });
		if (loginResponse.status === 'connected') {
			const fbResponse = await Promisfy(FB.api, '/me?fields=id,email,name');
			if (fbResponse.email) {
				const rawApiResponse = await fetch(API_URL + '/fbhandle', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ email: fbResponse.email, authResponse: loginResponse.authResponse, link: true })
				});
				const apiResponse = await rawApiResponse.json();
				if (apiResponse.error) {
					addToast(t('common.error') + apiResponse.error.message, { appearance: 'error', fontFamily: theme.fonts.regular });
				} else {
					if (apiResponse.user) setUser(apiResponse.user);
					addToast(t('profile.linkSuccess'), { appearance: 'success', fontFamily: theme.fonts.regular });
				}
			} else {
				addToast(t('profile.fbEmailErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
			}
		} else {
			addToast(t('profile.fbLinkErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
		}
		setFacebookLoading(false);
	}

	//Used effect
	useEffect(() => {
		isProfilePageHandle(true);
		return () => isProfilePageHandle(false);
	}, []);

	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Spacing mt={40}/>
			<h1 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('profile.title')}</h1>
			<Spacing mt={40}/>
			<TextField onChange={emailHandle} value={email} type="email" placeholder={t('profile.email')}/>
			<Spacing mt={15}/>
			<Select onChange={langHandle} value={lang} items={langItems} withLabel containerStyle={{ maxWidth: '80vw' }}
				inputStyle={{ width: 340, height: 45, fontFamily: theme.fonts.regular }}/>
			<Spacing mt={15}/>
			<div style={{ display: 'flex' }}>
				<input type="checkbox" id="darkMode" checked={darkMode} onChange={(e) => darkModeHandle(e.target.checked)}/>
				<Spacing mr={5}/>
				<label htmlFor="darkMode">{t('profile.darkMode')}</label>
			</div>
			<Spacing mt={15}/>
			<Button title={!user.fb ? t('profile.linkFacebook') : t('profile.relinkFacebook')} onClick={linkFacebook}
				icon={FacebookIcon} loading={facebookLoading} inputStyle={{ backgroundColor: '#3b5998' }}/>
			<Spacing mt={20}/>
			<span style={{ fontFamily: theme.fonts.bold }}>{t('profile.changePassword')}</span>
			<Spacing mt={15}/>
			<TextField onChange={oldPasswordHandle} value={oldPassword} type="password" placeholder={t('profile.oldPassword')}/>
			<Spacing mt={15}/>
			<TextField onChange={newPasswordHandle} value={newPassword} type="password" placeholder={t('profile.newPassword')}/>
			<Spacing mt={15}/>
			<TextField onChange={confirmNewPasswordHandle} value={confirmNewPassword} type="password" placeholder={t('profile.confirmNewPassword')}/>
			<Spacing mt={25}/>
			<Button title={t('profile.save')} onClick={save} loading={actionLoading}/>
			<Spacing m={10}/>
		</div>
	);

}