import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

//Translations object
export const languages = {
	'en': { name: 'English', isRTL: false, translations: () => require('./langs/en.json') }
};

//Initialize localization
export const initLocalization = () => {
    i18n.use(LanguageDetector).use(initReactI18next)
    .init({
		interpolation: { escapeValue: false },
		fallbackLng: 'en',
		resources: {
			en: { translation: languages.en.translations() }
		}
    });
}