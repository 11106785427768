import React from 'react';
import { Routes, Route } from "react-router-dom";
import { AUTH_STACK as STACK } from "../../pages";

export default function AuthStack () {

	//Get routes names
	const routesNames = Object.keys(STACK.screens);

	return (
		<Routes>
			<Route index element={<STACK.index.screen />} />
			{routesNames.map((name) => {
				const Component = STACK.screens[name];
				return <Route key={name} path={"/" + name} element={<Component/>} />;
			})}
			<Route path="*" element={<STACK.notFound />} />
		</Routes>
	);

}