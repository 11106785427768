import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ToastProvider, DefaultToast } from 'react-toast-notifications';
import i18n from "i18next";
import { Home, UserApp, DataDeletion, PrivacyPolicy } from '../pages';
import App from "./App";

const CustomToast = ({ children, ...props }) => (
	<DefaultToast {...props}>
		{props.fontFamily ? <span style={{ fontFamily: props.fontFamily }}>{children}</span> : children}
	</DefaultToast>
);

export default function Root () {

	return (
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<ToastProvider autoDismiss placement="top-center" components={{ Toast: CustomToast }}>
					<Suspense fallback={<></>}>
						<Routes>
							<Route index element={<Home />}/>
							<Route path="/home" element={<Home />}/>
							<Route path="/data-deletion" element={<DataDeletion />}/>
							<Route path="/privacy-policy" element={<PrivacyPolicy />}/>
							<Route path="/404" element={<Home notFound />}/>
							<Route path="/account/*" element={<App />}/>
							<Route path="/:url" element={<UserApp />} />
						</Routes>
					</Suspense>
				</ToastProvider>
			</I18nextProvider>
		</BrowserRouter>
	);

}