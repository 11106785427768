import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTheme } from '../utils';
import ArrowDownIcon from '../assets/svg/arrowDown.svg';

export default function Select ({ onChange=()=>{}, value, items=[], withLabel, containerStyle={}, inputStyle={} }) {

	//Get theme
	const theme = useTheme();

    //Create styles
	const styles = StyleSheet.create({
		select: {
			':focus': {
				outline: 'none'
			}
		}
	});

	return (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', ...containerStyle }}>
            <select onChange={(e) => onChange(e.target.value)} value={value} className={css(styles.select)} style={{ borderStyle: 'none',
                appearance: 'none', MozAppearance: 'none', WebkitAppearance: 'none', fontSize: 15, padding: '10px 40px 10px 20px',
                ...(theme ? {
                    backgroundColor: theme.colors.input, boxShadow: theme.shadow.input, borderRadius: theme.borderRadius.input,
                    fontFamily: theme.fonts.bold, color: theme.colors.textPrimary
                } : {
                    backgroundColor: '#ffffff', boxShadow: '0 0 8px #00000019', borderRadius: 35, fontFamily: 'Proxima Nova Bold, sans-serif'
                }),
                ...inputStyle }}>
                {withLabel ? (
                    items.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)
                ) : (
                    items.map((item, i) => <option key={i} value={item}>{item}</option>)
                )}
            </select>
            <img src={ArrowDownIcon} width={10} style={{ position: 'absolute', right: 15 }}/>
        </div>
	);

}