
//Method for uploading and resizing an image
export const ImageUpload = (width, height, type=null, isImage=true) => {
    return new Promise((resolve, reject) => {
        const fileElm = document.createElement('input');
        fileElm.setAttribute('type', 'file');
        if (type) fileElm.setAttribute('accept', 'image/' + type);
        fileElm.setAttribute('hidden', true);
        fileElm.onchange = () => {
            const file = fileElm.files[0];
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                const fileType = reader.result.substring(reader.result.indexOf('/') + 1, reader.result.indexOf(';'));
                if (type && fileType !== type) {
                    reject('FILE_TYPE_INVALID');
                    return;
                }
                if (width && height) {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = width;
                        canvas.height = height;
                        const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
                        const x = (canvas.width / 2) - (img.width / 2) * scale;
                        const y = (canvas.height / 2) - (img.height / 2) * scale;
                        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
                        const base64Data = canvas.toDataURL('image/' + fileType);
                        img.remove();
                        canvas.remove();
                        resolve(base64Data);
                    };
                    img.src = reader.result;
                } else {
                    resolve(reader.result);
                }
                fileElm.remove();
            }, false);
            if (file) {
                if (isImage && !file.type.match('image.*')) {
                    reject('FILE_TYPE_INVALID');
                    return;
                }
                reader.readAsDataURL(file);
            }
        };
        fileElm.click();
    });
}
