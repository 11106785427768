{
    "common": {
        "404": "404",
        "notFound": "Not Found!",
        "errorMsg": "Something went wrong!",
        "tryAgain": "Try again",
        "profile": "Profile",
        "logout": "Logout",
        "error": "Error: ",
        "confirmMsg": "Are you sure?",
        "done": "Done!"
    },
    "dataDeletion": {
        "title": "Data Deletion Request",
        "msg": "If you are a registered user and you would like to permanently delete your data from our servers, then please send us an email at frame0studio@gmail.com"
    },
    "privacyPolicy": {
        "title": "Privacy Policy",
        "msg1": "This SERVICE is provided by FrameStudio and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.",
        "msg2Title": "Cookies",
        "msg2": "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.",
        "msg3Title": "Service Providers",
        "msg3": "We may employ third-party companies and individuals due to the following reasons: To facilitate our Service, To provide the Service on our behalf, To perform Service-related services. or, To assist us in analyzing how our Service is used. We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
        "msg4Title": "Data Deletion Request",
        "msg4": "You have the right to ask for your personal information to be deleted from our servers. If you would like to exercise this right, please send a request at this email address frame0studio@gmail.com",
        "msg5Title": "Changes to This Privacy Policy",
        "msg5": "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.\nThis policy is effective as of 2022-01-01",
        "msg6Title": "Contact Us",
        "msg6": "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at frame0studio@gmail.com"
    },
    "home": {
        "login": "Login",
        "signup": "Sign up",
        "searchMsg": "Search using any keyword...",
        "callMsg": "Create your own campaigns now",
        "notFoundMsg": "Sorry this frame is not found!",
        "emptyMsg": "No campaigns found!",
        "contactMsg": "Contact: frame0studio[at]gmail.com"
    },
    "userapp": {
        "preview": "Preview",
        "crop": "Crop",
        "previewMsg": "Your picture here",
        "browseImage": "Browse Image",
        "fbPicture": "Upload From Facebook",
        "downloadImage": "Download Image",
        "callToAction1": "Do you want to create and manage your own campaigns?",
        "callToAction2": "Click here",
        "callToAction3": "to create your free account and start immediately.",
        "fileTypeErr": "File type invalid!\nPlease upload an image",
        "fbLoginErr": "Error: Could not login with Facebook!"
    },
    "login": {
        "email": "Email",
        "password": "Password",
        "login": "Login",
        "noAccountMsg": "Don't have an account?",
        "signup": "Sign up",
        "forgotMsg": "Forgot Password?",
        "or": "or",
        "connectFacebook": "Continue with Facebook",
        "missingErr": "Missing Fields!",
        "emailValidErr": "Email invalid!",
        "passwordCountErr": "Password should consist of at least 8 characters!",
        "userInvalidErr": "Error: Email/Password invalid!",
        "fbLoginErr": "Error: Could not login with Facebook!",
        "fbEmailErr": "Error: Could not get email address from Facebook!",
        "fbAccountErr": "Error: Facebook account not valid! Please signup first.",
        "fbLinkErr": "Error: Please link facebook first in your settings."
    },
    "signup": {
        "title": "Create an Account",
        "email": "Email",
        "password": "Password",
        "confirmPassword": "Confirm password",
        "signup": "Sign up",
        "or": "or",
        "connectFacebook": "Continue with Facebook",
        "setPassword": "Set Password",
        "missingErr": "Missing Fields!",
        "emailValidErr": "Email invalid!",
        "passwordCountErr": "Password should consist of at least 8 characters!",
        "passwordMismatchErr": "Password mismatch!",
        "emailExistErr": "Error: Email already exists!",
        "fbSignupErr": "Error: Could not signup with Facebook!",
        "fbEmailErr": "Error: Could not get email address from Facebook!"
    },
    "forgot": {
        "title": "Forgot Password",
        "resetTitle": "Set New Password",
        "email": "Email",
        "password": "Password",
        "confirmPassword": "Confirm password",
        "resetPassword": "Reset Password",
        "emailValidErr": "Email invalid!",
        "sentMsg": "Password reset link sent!\nPlease check your email inbox.",
        "emailNotExistErr": "Email does not exist!",
        "missingErr": "Missing Fields!",
        "passwordCountErr": "Password should consist of at least 8 characters!",
        "passwordMismatchErr": "Password mismatch!",
        "successMsg": "Password changed successfully!",
        "linkInvalidErr": "Reset link invalid!",
        "linkExpiredErr": "Reset link expired! Please try again."
    },
    "campaigns": {
        "myTitle": "My Campaigns",
        "title": "%s: Campaigns",
        "myEmptyMsg": "You don’t have any campaigns yet!",
        "emptyMsg": "User doesn’t have any campaigns yet!",
        "createCampaign": "Create Campaign",
        "url": "URL",
        "dateCreated": "Date created",
        "daysRunning": "Days running",
        "totalCampaignViews": "Total campaign views",
        "totalFrameDownloads": "Total frame downloads",
        "missingParamsErr": "Missing parameters!"
    },
    "users": {
        "title": "Users",
        "emptyMsg": "No users found!",
        "searchMsg": "Search...",
        "exportUsers": "Export Users",
        "clearStats": "Clear Analytics ( > 30 Days )",
        "showStats": "Show Stats",
        "dateCreated": "Date joined",
        "totalCampaigns": "Total campaigns",
        "totalUsers": "Total users",
        "exportMsg": "This will export all your users to a .csv file\nAre you sure?",
        "id": "ID",
        "email": "Email",
        "campaignUrl": "Campaign URL",
        "searchBy": "Search by",
        "sortBy": "Sort by",
        "latestUser": "Latest created user",
        "latestCampaign": "Latest created campaign",
        "campaignsTotal": "Total number of campaigns",
        "campaignViews": "Most viewed campaign",
        "campaignDownloads": "Most downloaded campaign",
        "campaignDateMsg": "Last campaign created",
        "campaignViewsMsg": "Most campaign views",
        "campaignDownloadsMsg": "Most campaign downloads"
    },
    "profile": {
        "title": "Profile",
        "email": "Email",
        "darkMode": "Dark Mode",
        "linkFacebook": "Link Facebook",
        "relinkFacebook": "Re-Link Facebook",
        "changePassword": "Change Password",
        "oldPassword": "Old password",
        "newPassword": "New password",
        "confirmNewPassword": "Confirm new password",
        "save": "Save",
        "saveSuccess": "Profile saved successfully!",
        "linkSuccess": "Facebook linked successfully!",
        "missingErr":"Missing Fields!",
        "emailValidErr": "Email invalid!",
        "passwordCountErr": "passwordCountErr",
        "passwordMismatchErr": "Password mismatch!",
        "oldPasswordErr": "Error: Old password incorrect!",
        "fbLinkErr": "Error: Could not link Facebook!",
        "fbEmailErr": "Error: Could not get email address from Facebook!"
    },
    "stats": {
        "url": "URL",
        "dateCreated": "Date created",
        "daysRunning": "Days running",
        "from": "From",
        "to": "To",
        "totalCampaignViews": "Total campaign views",
        "totalFrameDownloads": "Total frame downloads",
        "languages": "Languages",
        "allFrames": "All frames",
        "views": "Views",
        "downloads": "Downloads",
        "idNotValidErr":"Campaign ID not valid!"
    },
    "manage": {
        "createCampaign": "Create Campaign",
        "editCampaign": "Edit Campaign",
        "campaignName": "Campaign name",
        "url": "URL",
        "multiLanguage": "Multi-language",
        "watermarkMsg": "Add campaign URL watermark",
        "downloadSize": "Download size",
        "size": "Size",
        "addLanguage": "Add Language",
        "campaignTitle": "Campaign title (*)",
        "campaignSubtitle": "Campaign subtitle",
        "languageMsg": "Language title (*) (ex: EN)",
        "frameImages": "Frame images",
        "creditMsg": "Give credit to the frame artist here",
        "infoMsg": "Don’t forget to make the central part of your frame transparent, so profile photos can be seen through it!",
        "save": "Save",
        "helpVideos": "Help Videos",
        "multiLanguageMsg": "Deactivating multi-language will delete the languages you have!\nAre you sure?",
        "sizeErr": "Download size invalid!\nPlease set atleast %sx%s",
        "fileTypeErr": "File type invalid!\nPlease upload a .png file",
        "fileSizeErr": "Image size exceeded max limit of %sMB!\nPlease choose a smaller image",
        "docSizeErr": "Campaign size exceeded max limit of %sMB!\nPlease decrease the number of images or re-upload smaller images",
        "missingErr": "Missing Fields!",
        "missingFramesErr": "Frame images are required!",
        "saveSuccess": "Campaign saved successfully!",
        "idNotValidErr": "Campaign ID not valid!"
    }
}