import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useToasts } from 'react-toast-notifications';
import { API_URL } from '../config';
import { useTheme } from '../utils';
import { Spacing, TextField, Button, LoadingElm } from '../components';
import * as EmailValidator from 'email-validator';
import ArrowBackIcon from '../assets/svg/arrowLeft.svg';
import ProblemIcon from '../assets/svg/problem.svg';

export default function Forgot () {

	//Used hooks
	const { t } = useTranslation();
	const location = useLocation();
	const theme = useTheme();
	const { addToast } = useToasts();
	const isMobile = useMediaQuery({ query: '(max-height: 320px)' });

	//Used variables
	const urlParams = new URLSearchParams(location.search);
	const reset = urlParams.get('reset');

	//Used state
	const [ loading, setLoading ] = useState(!(!reset));
	const [ error, setError ] = useState(null);
	const [ successMsg, setSuccessMsg ] = useState(false);
	const [ actionLoading, setActionLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');

	//Used methods
	const emailHandle = useCallback((value) => setEmail(value), []);
	const passwordHandle = useCallback((value) => setPassword(value), []);
	const confirmPasswordHandle = useCallback((value) => setConfirmPassword(value), []);

	const resetPassword = () => {
		if (!reset) {
			if (!EmailValidator.validate(email)) {
				addToast(t('forgot.emailValidErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				return;
			}
			setActionLoading(true);
			fetch(API_URL + '/forgot', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email })
			})
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
				setEmail('');
				addToast(t('forgot.sentMsg'), { appearance: 'success', fontFamily: theme.fonts.regular });
			})
			.catch((err) => {
				if (err.message === 'INVALID_USER') addToast(t('forgot.emailNotExistErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				else addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular });
			})
			.finally(() => setActionLoading(false));
		} else {
			if (!password || !confirmPassword) {
				addToast(t('forgot.missingErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				return;
			} else if (password.length < 8) {
				addToast(t('forgot.passwordCountErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				return;
			} else if (password !== confirmPassword) {
				addToast(t('forgot.passwordMismatchErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				return;
			}
			setActionLoading(true);
			fetch(API_URL + '/reset', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ code: reset, password })
			})
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
				setSuccessMsg(true);
			})
			.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
			.finally(() => setActionLoading(false));
		}
	}

	//Used effect
	useEffect(() => {
		if (reset) {
			fetch(API_URL + '/checkcode', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ code: reset })
			})
			.then((res) => res.json())
			.then((res) => {
				if (res.error) throw new Error(res.error.message);
			})
			.catch((err) => {
				if (err.message === 'LINK_INVALID') setError(t('forgot.linkInvalidErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				else if (err.message === 'LINK_EXPIRED') setError(t('forgot.linkExpiredErr'), { appearance: 'error', fontFamily: theme.fonts.regular });
				else setError(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular });
			})
			.finally(() => setLoading(false));
		}
	}, []);

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center',
			justifyContent: !isMobile ? 'center' : undefined, overflow: 'auto',
			backgroundColor: theme.colors.background, color: theme.colors.textPrimary, fontFamily: theme.fonts.regular }}>
			{isMobile && (loading || error || successMsg) && <Spacing mt={40}/>}
			{loading ? <>
				<LoadingElm/>
			</> : error ? <>
				<img src={ProblemIcon} width={100}/>
				<Spacing mt={20}/>
				<span style={{ color: theme.colors.textSecondary }}>{error}</span>
			</> : successMsg ? <>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('forgot.successMsg')}</h3>
			</> : <>
				{!reset && <Link to="/account" style={{ position: 'absolute', top: 30, left: 40 }}><img src={ArrowBackIcon} width={12}/></Link>}
				<h1 style={{ fontFamily: theme.fonts.bold, marginTop: !isMobile ? undefined : 50 }}>
					{!reset ? t('forgot.title') : t('forgot.resetTitle')}
				</h1>
				<Spacing mt={30}/>
				{!reset ? <>
					<TextField onChange={emailHandle} onEnter={resetPassword} value={email} type="email" placeholder={t('forgot.email')}/>
				</> : <>
					<TextField onChange={passwordHandle} value={password} type="password" placeholder={t('forgot.password')}/>
					<Spacing mt={15}/>
					<TextField onChange={confirmPasswordHandle} value={confirmPassword} type="password"
						placeholder={t('forgot.confirmPassword')} onEnter={resetPassword}/>
				</>}
				<Spacing mt={25}/>
				<Button title={t('forgot.resetPassword')} onClick={resetPassword} loading={actionLoading}/>
			</>}
			<Spacing m={10}/>
        </div>
	);

}