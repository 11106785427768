import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useTheme, useUser } from '../utils';
import { API_URL } from '../config';
import { Spacing, Button, LoadingElm } from '../components';
import ReactLoading from "react-loading";
import InfoIcon from '../assets/svg/info.svg';
import ProblemIcon from '../assets/svg/problem.svg';
import DeleteIcon from '../assets/svg/delete.svg';
import EditIcon from '../assets/svg/edit.svg';
import StatsIcon from '../assets/svg/statistics.svg';
import DeactivateIcon from '../assets/svg/deactivate.svg';
import ActivateIcon from '../assets/svg/activate.svg';
import FreezeIcon from '../assets/svg/freeze.svg';
import DefreezeIcon from '../assets/svg/defreeze.svg';

export default function Campaigns () {

	//Used hooks
	const { t } = useTranslation();
	const location = useLocation();
	const theme = useTheme();
	const { user } = useUser();
	const { addToast } = useToasts();

	//Used parameters
	const urlParams = new URLSearchParams(location.search);
	const user_id = urlParams.get('id');
	const email = urlParams.get('email');

	//Set used state
    const [ loading, setLoading ] = useState(false);
    const [ removeLoading, setRemoveLoading ] = useState(null);
    const [ freezeLoading, setFreezeLoading ] = useState(null);
    const [ deactivateLoading, setDeactivateLoading ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ items, setItems ] = useState([]);

	//Used variables
	const empty = items.length === 0;

	//Used methods
	const remove = (index, _id) => {
		if (removeLoading !== null) return;
		setRemoveLoading(index);
		fetch(API_URL + '/campaign', {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ _id })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			const newItems = [ ...items ];
			newItems.splice(index, 1);
			setItems(newItems);
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setRemoveLoading(null));
	}

	const freeze = (index, item) => {
		if (freezeLoading !== null) return;
		setFreezeLoading(index);
		const newFrozen = !item.frozen;
		fetch(API_URL + '/campaign', {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ _id: item._id, params: { frozen: newFrozen } })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			const newItems = [ ...items ];
			newItems[index].frozen = newFrozen;
			setItems(newItems);
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setFreezeLoading(null));
	}

	const deactivate = (index, item) => {
		if (deactivateLoading !== null) return;
		setDeactivateLoading(index);
		const newActive = !item.active;
		fetch(API_URL + '/campaign', {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
			body: JSON.stringify({ _id: item._id, params: { active: newActive } })
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			const newItems = [ ...items ];
			newItems[index].active = newActive;
			setItems(newItems);
		})
		.catch((err) => addToast(t('common.error') + err.message, { appearance: 'error', fontFamily: theme.fonts.regular }))
		.finally(() => setDeactivateLoading(null));
	}

	const fetchCampaigns = () => {
		if (user.admin && (!user_id || !email)) {
			setError(t('campaigns.missingParamsErr'));
			return;
		}
		setLoading(true);
		setError(null);
		fetch(API_URL + '/campaign?user_id=' + (!user.admin ? user._id : user_id))
		.then((res) => res.json())
		.then((res) => {
			if (res.error) throw new Error(res.error.message);
			if (res.items) setItems(res.items);
		})
		.catch((err) => setError(err.message))
		.finally(() => setLoading(false));
	}

	useEffect(fetchCampaigns, []);

	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', width: '80%' }}>
			<Spacing mt={40}/>
			<h1 style={{ margin: 0, fontFamily: theme.fonts.bold }}>
				{!user.admin ? t('campaigns.myTitle') : !loading && !error ? t('campaigns.title').replace('%s', email) : null}
			</h1>
			<Spacing mt={40}/>
			{loading ? <>
				<div style={{ flex: 1 }}></div>
				<LoadingElm/>
				<div style={{ flex: 1 }}></div>
				<Spacing m={20}/>
			</> : error ? <>
				<div style={{ flex: 1 }}></div>
				<img src={ProblemIcon} width={100}/>
				<Spacing mt={20}/>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{t('common.errorMsg')}</h3>
				<Spacing mb={5}/>
				<span style={{ color: theme.colors.textSecondary }}>{error}</span>
				<Spacing mb={20}/>
				<Button title={t('common.tryAgain')} onClick={fetchCampaigns}/>
				<div style={{ flex: 1 }}></div>
				<Spacing m={20}/>
			</> : empty ? <>
				<div style={{ flex: 1 }}></div>
				<img src={InfoIcon} width={100}/>
				<Spacing mt={20}/>
				<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>
					{!user.admin ? t('campaigns.myEmptyMsg') : t('campaigns.emptyMsg')}
				</h3>
				<Spacing mb={20}/>
				{!user.admin && <>
					<Link to="/account/manage" style={{ textDecoration: 'none' }}>
						<Button title={t('campaigns.createCampaign')}/>
					</Link>
				</>}
				<div style={{ flex: 1 }}></div>
				<Spacing m={20}/>
			</> : <>
				{!user.admin && <>
					<Link to="/account/manage" style={{ alignSelf: 'flex-end', textDecoration: 'none' }}>
						<Button title={t('campaigns.createCampaign')}/>
					</Link>
					<Spacing mt={20}/>
				</>}
				{items.map((item, i) => <div key={item._id} style={{ padding: '25px', width: '100%', boxSizing: 'border-box', marginBottom: 20,
					backgroundColor: theme.colors.card, borderRadius: theme.borderRadius.card, boxShadow: theme.shadow.card,
					display: 'flex', alignItems: 'center', overflow: 'auto' }}>
					<div style={{ flex: 1 }}>
						<h3 style={{ margin: 0, fontFamily: theme.fonts.bold }}>{item.name}</h3>
						<br/>
						<b>{t('campaigns.url')+': '}</b>
						<a href={window.location.origin + '/' + item.url} target='_blank' style={{ color: theme.colors.textSecondary }}>
							{window.location.origin + '/' + item.url}
						</a>
						<br/>
						<b>{t('campaigns.dateCreated')+': '}</b>
						<span style={{ color: theme.colors.textSecondary }}>{new Date(item.date).toLocaleDateString()}</span>
						<br/>
						<b>{t('campaigns.daysRunning')+': '}</b>
						<span style={{ color: theme.colors.textSecondary }}>{(() => {
							const now = new Date().getTime();
							const created = new Date(item.date).getTime();
							const diff = Math.round((now - created) / 86400000);
							return diff.toString();
						})()}</span>
						<br/>
						<b>{t('campaigns.totalCampaignViews')+': '}</b>
						<span style={{ color: theme.colors.textSecondary }}>{item.views.toString()}</span>
						<br/>
						<b>{t('campaigns.totalFrameDownloads')+': '}</b>
						<span style={{ color: theme.colors.textSecondary }}>{item.downloads.toString()}</span>
					</div>
					<Spacing mr={5}/>
					<div style={{ display: 'flex' }}>
						{user.admin && <>
							{freezeLoading === i ? (
								<ReactLoading type="spinningBubbles" width={14} height={14} color={theme.colors.textSecondary}/>
							) : (
								<img onClick={() => freeze(i, item)} src={!item.frozen ? FreezeIcon : DefreezeIcon}
									height={18} style={{ cursor: 'pointer' }}/>
							)}
							<Spacing mr={15}/>
							{deactivateLoading === i ? (
								<ReactLoading type="spinningBubbles" width={14} height={14} color={theme.colors.textSecondary}/>
							) : (
								<img onClick={() => deactivate(i, item)} src={item.active ? DeactivateIcon : ActivateIcon}
									height={18} style={{ cursor: 'pointer' }}/>
							)}
							<Spacing mr={15}/>
						</>}
						<Link to={"/account/stats/" + item._id}><img src={StatsIcon} height={18}/></Link>
						<Spacing mr={15}/>
						<Link to={"/account/manage?id=" + item._id}><img src={EditIcon} height={18}/></Link>
						<Spacing mr={15}/>
						{removeLoading === i ? (
							<ReactLoading type="spinningBubbles" width={14} height={14} color={theme.colors.textSecondary}/>
						) : (
							<img onClick={() => {
								if (confirm(t('common.confirmMsg'))) remove(i, item._id);
							}} src={DeleteIcon} height={18} style={{ cursor: 'pointer' }}/>
						)}
					</div>
				</div>)}
				<Spacing m={15}/>
			</>}
		</div>
	);

}