import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useTheme } from '../utils';

export default function TextField ({ onChange=()=>{}, value, type, placeholder, icon, enterIcon, closeIcon, onEnter, onClose,
	containerStyle={}, inputStyle={} }) {

	//Get theme
	const theme = useTheme();
    //Create styles
	const styles = StyleSheet.create({
		input: {
			':focus': {
				outline: 'none'
			},
			'::placeholder': {
				color: theme ? theme.colors.inputPlaceholder : '#bcbcbc'
			}
		}
	});

	return (
        <div style={{ position: 'relative', maxWidth: '80vw', display: 'flex', alignItems: 'center', ...containerStyle }}>
			{icon && <img src={icon} width={18} style={{ position: 'absolute', left: 20 }}/>}
			<input className={css(styles.input)} onChange={(e) => onChange(e.target.value)} value={value} type={type}
				onKeyUp={onEnter ? ((e) => e.key === 'Enter' ? onEnter() : undefined) : undefined}
				placeholder={placeholder} style={{
					border: 'none', fontSize: 15, width: 300,
					...(icon ? { padding: !enterIcon ? '15px 20px 15px 55px' : '15px 70px 15px 55px' } : { padding: '15px 20px' }),
					...(theme ? {
						backgroundColor: theme.colors.input, boxShadow: theme.shadow.input, borderRadius: theme.borderRadius.input,
						fontFamily: theme.fonts.Regular, color: theme.colors.textPrimary
					} : {
						backgroundColor: '#ffffff', boxShadow: '0 0 8px #00000019', borderRadius: 35,
						fontFamily: 'Proxima Nova Regular, sans-serif'
					}),
					...inputStyle
				}}
			/>
			{closeIcon && <img src={closeIcon} width={20} onClick={onClose} style={{ cursor: 'pointer', position: 'absolute', right: 40 }}/>}
			{enterIcon && <img src={enterIcon} height={14} onClick={onEnter} style={{ cursor: 'pointer', position: 'absolute', right: 20 }}/>}
		</div>
	);

}