import React from 'react';
import { useTheme } from '../utils';
import { LoadingElm } from '../components';

export default function Loading () {

	//Get theme
	const theme = useTheme();

	return (
		<div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
			backgroundColor: theme.colors.background, overflow: 'auto' }}>
            <LoadingElm/>
        </div>
	);

}