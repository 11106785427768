import React, { useState, useEffect } from 'react';
import i18n from "i18next";
import { ThemeProvider, UserProvider } from "../utils";
import { API_URL } from '../config';
import { Loading, Error as ErrorElm } from '../pages';
import AuthStack from "./stacks/AuthStack";
import UserStack from "./stacks/UserStack";

export default function App () {

	//Set used state
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ user, setUser ] = useState(null);

    //Used methods
    const fetchUser = () => {
        const token = localStorage.getItem('token');
        var lang = null;
        if (token) {
            setLoading(true);
            setError(null);
            setUser(null);
            fetch(API_URL + '/user', { headers: { 'Authorization': 'Bearer ' + token } })
            .then((res) => res.json())
            .then((res) => {
                if (res.error) throw new Error(res.error.message);
                if (res.user) {
                    lang = res.user.lang;
                    setUser(res.user);
                }
            })
            .catch((err) => {
                if (err.message === 'TOKEN_NOT_FOUND' || err.message === 'USER_NOT_FOUND' || err.message === 'jwt expired') {
                    localStorage.setItem('token', '');
                } else {
                    setError(err.message);
                }
            })
            .finally(() => {
                if (i18n.language !== lang) i18n.changeLanguage(lang);
                setLoading(false);
            });
        }
    }

    //Used effect
    useEffect(fetchUser, []);

	return (
        <ThemeProvider theme={user && user.theme ? user.theme : undefined}>
            <UserProvider user={{ user, setUser }}>
                {loading ? <Loading /> : error ? <ErrorElm message={error} callback={fetchUser} /> : (
                    !user ? <AuthStack /> : <UserStack admin={user.admin}/>
                )}
            </UserProvider>
        </ThemeProvider>
	);

}