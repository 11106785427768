import React from 'react';
import { useTheme } from '../utils';
import ReactLoading from "react-loading";

export default function Button ({ title, onClick, icon, outline, loading, loadingColor, containerStyle={}, inputStyle={} }) {

	//Get theme
	const theme = useTheme();
	//Set variables
	const color = theme ? theme.colors.primary : '#df2e2e';
	const textColor = theme ? theme.colors.textOnPrimary : '#ffffff';

	return (
		<div onClick={onClick} style={{ position: 'relative', display: 'flex', alignItems: 'center', cursor: 'pointer', ...containerStyle }}>
			{icon && <img src={icon} height={18} style={{ position: 'absolute', left: 25 }}/>}
			<div style={{
				border: 'none', background: 'none', fontSize: 16, height: 20, display: 'flex', alignItems: 'center', justifyContent: 'center',
				...(icon ? { padding: '12px 30px 12px 50px' } : { padding: '12px 30px' }),
				...(theme ? {
					borderRadius: theme.borderRadius.input, fontFamily: theme.fonts.Regular
				} : {
					borderRadius: 35, fontFamily: 'Proxima Nova Regular, sans-serif'
				}),
				...(!outline ? {
					backgroundColor: color, color: textColor
				} : {
					borderStyle: 'solid', borderWidth: 2, borderColor : color, color: color
				}),
				...inputStyle
			}}>
				{loading ? (
					<ReactLoading type="spinningBubbles" width={24} height={24} color={loadingColor || (!outline ? textColor : color)}/>
				) : title}
			</div>
		</div>
	);

}